import React, {FunctionComponent} from 'react';
import AgentAvatar from '../../../components/agentAvatar';
import useDetermineAgentName from '../../../foundations/hooks/useDetermineAgentName';

const Connected: FunctionComponent = () => {
  const agentName = useDetermineAgentName();

  return (
    <div className='zchat__status'>
      <AgentAvatar/>
      <p className='zchat__status-text zchat__status-text--dark'>Connected to
        <span className='zchat__agent-name'> {agentName}</span>
      </p>
    </div>
  );
}

export default React.memo(Connected);
