import {CloseChatAction} from '../chat/types';

export const SET_HISTORY = 'SET_HISTORY';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_READ_MESSAGES_STATE = 'SET_READ_MESSAGES_STATE';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export interface MessageInterface {
  id: string,
  uid: string,
  type: MessageType,
  user: string,
  body: string,
  datetime: string,
  timestamp: number,
  clientid?: string,
  read?: boolean,
}

export type Messages = MessageInterface[];

export type MessageType = 'incoming' | 'outgoing' | 'comment' | 'xaction' | 'system' | 'agent_typing' | 'agent_untyping'

export interface MessagesState {
  messages: Messages | null,
  loading: boolean,
}

interface SetHistory {
  type: typeof SET_HISTORY
  payload: Messages,
}

interface AddMessage {
  type: typeof ADD_MESSAGE
  payload: MessageInterface,
}

interface SetReadMessagesState {
  type: typeof SET_READ_MESSAGES_STATE
  payload: Record<string, never>,
}

export interface SetLoadingStatus {
  type: typeof SET_LOADING_STATUS
  payload: {status: boolean},
}

export type MessagesActionTypes = SetHistory | AddMessage | SetReadMessagesState | CloseChatAction | SetLoadingStatus;
