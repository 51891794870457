export default function getOsName() {
    let userAgent = navigator.userAgent;
    let os;

    if (/iPhone|iPad|iPod/.test(userAgent)) {
        os = 'ios';
    } else if (/Android/.test(userAgent)) {
        os = 'android';
    } else {
        os = 'other';
    }

    return os;
}
