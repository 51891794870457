import {connect} from 'react-redux';
import {setTooltipState} from '../../store/chat/actions';
import Tooltip from './tooltip';
import {TooltipState} from '../../store/chat/types';

const mapDispatchToProps = (dispatch: any) => ({
  setTooltipState: (state: TooltipState): void => {
    dispatch(setTooltipState(state));
  }
});

export default connect(
  null,
  mapDispatchToProps,
)(Tooltip);
