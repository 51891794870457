import getLandName from "../../foundations/utils/getLandName";

export type SuggestionsNewProductConfigType = {
  id: string,
  text: string,
  event?: string,
};



export type SuggestionsConfigType = Array<{
  [key: string]: Array<SuggestionsNewProductConfigType>
}>

export let SuggestionsNewConfig: Array<SuggestionsNewProductConfigType>;
export let SuggestionsNewConfigWeb2app: Array<SuggestionsNewProductConfigType>;
export let SuggestionsNewConfigWta: Array<SuggestionsNewProductConfigType>;

if (getLandName('clario') === '325' || getLandName('clario') === '326') {
  SuggestionsNewConfig = [
    {
      id: '0',
      text: 'My account has been compromised',
    },
    {
      id: '1',
      text: 'I’m a financial fraud victim',
    },
    {
      id: '2',
      text: 'Credit freeze or fraud alert',
    },
    {
      id: '3',
      text: 'Need more info on password protection',
    },
    {
      id: '4',
      text: 'Someone stole my personal data',
    },
    {
      id: '5',
      text: 'I’ve suffered from the identity theft',
    },
    {
      id: '6',
      text: 'Other',
    },
  ]
} else if (getLandName('clario') === 'identity-protection' || getLandName('clario') === '327' || getLandName('clario') === '328' || getLandName('clario') === '329') {
  SuggestionsNewConfig = [
    {
      id: '0',
      text: 'My email has been breached',
      event: '//email_has_been_breached',
    },
    {
      id: '1',
      text: 'I’ve received a fraud alert',
      event: '//received_a_fraud_alert',
    },
    {
      id: '2',
      text: 'Tell me if my data has been breached',
      event: '//tell_if_my_data_has_been_breached',
    },
    {
      id: '3',
      text: 'I want to learn more about identity theft',
      event: '//learn_more_about_identity_theft',
    },
    {
      id: '4',
      text: 'Other',
    },
  ]
} else if (getLandName('mackeeper') === 'thankyoumk') {
  SuggestionsNewConfig = [
    {
      id: '0',
      text: 'MacKeeper features setup',
      event: '//mackeeper_features_setup',
    },
    {
      id: '1',
      text: 'Need help with full fix',
      event: '//need_help_with_full_fix',
    },
    {
      id: '2',
      text: 'Free Mac checkup',
      event: '//free_mac_checkup',
    },
    {
      id: '3',
      text: 'Need help with the software Mac issue',
      event: '//need_help_with_the_software_mac_issue',
    },
    {
      id: '4',
      text: 'Other',
      event: '//other'
    },
  ]
} else if (getLandName('mackeeper') === '335') {
  SuggestionsNewConfig = [
    {
      id: '0',
      text: 'My Mac is slow',
      event: '//my_mac_is_slow'
    },
    {
      id: '1',
      text: 'I have issues with apps',
      event: '//issues_with_apps'
    },
    {
      id: '2',
      text: 'I see pop-ups and ads',
      event: '//i_see_popups_and_ads'
    },
    {
      id: '3',
      text: 'Free Mac checkup',
      event: '//free_mac_checkup'
    },
    {
      id: '4',
      text: 'Other',
      event: '//custom_issue'
    }
  ]
} else if (window.location.href.indexOf('payproglobal') !== -1){
  SuggestionsNewConfig = [
    {
      id: '0',
      text: 'Activate MacKeeper',
      event: '//activateMK',
    },
    {
      id: '1',
      text: 'MacKeeper features setup',
      event: '//mackeeper_features_set-up',
    },
    {
      id: '2',
      text: 'Free Mac checkup',
      event: '//free_mac_checkup',
    },
    {
      id: '3',
      text: 'Need help with the software Mac issue',
      event: '//need_help_with_mac_issues',
    },
    {
      id: '4',
      text: 'Other',
      event: '//other',
    }
  ]
}  else {
  SuggestionsNewConfig = [
    {
      id: 'my_mac_is_slow',
      text: 'My Mac is slow',
    },
    {
      id: 'my_mac_got_a_virus',
      text: 'I think my Mac’s got a virus',
    },
    {
      id: 'i_see_too_many_ads',
      text: 'I see too many ads',
    },
    {
      id: 'out_of_storage_space',
      text: 'My Mac’s out of storage space',
    },
    {
      id: 'my_accounts_have_been_compromised',
      text: 'My accounts have been compromised',
    },
    {
      id: 'i_cant_upgrade',
      text: 'I can’t upgrade to a new macOS',
    },
    {
      id: 'i_cant_access_a_certain_website_or_app',
      text: 'I can’t access a certain website or app ',
    },
    {
      id: 'my_mac_doesnt_connect',
      text: 'My Mac doesn’t connect to a mouse/ printer/ headphones/ etc.',
    },
    {
      id: 'other',
      text: 'Other',
    },
  ]
}

SuggestionsNewConfigWeb2app = [
  {
    id: '0',
    text: 'How can Clario help me?',
    event: '//how_clario_can_help_me',
  },
  {
    id: '1',
    text: 'Protect my calls & texts',
    event: '//protect_my_calls_texts',
  },
  {
    id: '2',
    text: 'Keep my location private',
    event: '//keep_my_location_private',
  },
  {
    id: '3',
    text: 'Protect my social media from hacks',
    event: '//protect_my_social_media_from_hacks',
  },
  {
    id: '4',
    text: 'Custom request',
    event: '//custom_request',
  },
]

SuggestionsNewConfigWta = [
  {
    id: '0',
    text: 'I see too many ads',
    event: '//i_see_too_many_ads',
  },
  {
    id: '1',
    text: 'Online videos load slowly',
    event: '//online_videos_load_slowly',
  },
  {
    id: '2',
    text: 'My Mac is slow',
    event: '//my_mac_is_slow',
  },
  {
    id: '3',
    text: 'I think my Mac’s got a virus',
    event: '//i_think_my_macs_got_a_virus',
  },
  {
    id: '4',
    text: 'My accounts have been compromised',
    event: '//my_accounts_have_been_compromised',
  },
  {
    id: '5',
    text: 'My Mac’s out of storage space',
    event: '//my_macs_out_of_storage_space',
  },
]

