import {AgentActionTypes, AgentState, SET_AGENT_PROFILE_INFO, SET_AGENT_TYPING_STATUS} from './types'
import {CLOSE_CHAT} from '../chat/types';

const initialState: AgentState = {
  agentTyping: false,
  agentProfile: null,
}

export function agentReducer(
  state = initialState,
  action: AgentActionTypes
): AgentState {
  switch (action.type) {
    case SET_AGENT_TYPING_STATUS:
      return {
        ...state,
        agentTyping: action.payload.status,
      }
    case SET_AGENT_PROFILE_INFO:
      return {
        ...state,
        agentProfile: action.payload,
      }
    case CLOSE_CHAT:
      return {...initialState}
    default:
      return state
  }
}
