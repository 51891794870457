import React, {FunctionComponent} from 'react';
import Statuses from './statuses';
import StatusesWeb2app from './statuses/statusesWeb2app';
import StatusesWta from './statuses/statusesWta';
import {ConnectionStatus, getCloudFrontDomain} from '../../constants';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import {useZchatContext} from '../zchatUI';
import {ChatConfig} from '../../interfaces/zchat';
import {ProductName} from '../../interfaces/zchat';
// @ts-ignore
import avatars from '../../../resources/images/avatars.png';
import StatusesAfterSale from "./statusesAfterSale";

interface Header {
  chatConfig?: ChatConfig | null,
  productName?: ProductName,
}

const Header: FunctionComponent<Header> = ({chatConfig, productName}) => {
  const {toggleChat} = useZchatContext();
  const connectionStatus: ConnectionStatus = useSelector((state: RootState) => state.chat.connectionStatus);
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);
  let domain = getCloudFrontDomain(productName);

  if (!toggleChat) {
    return null;
  }

  const getResizeIcon = () => {
    let icon: JSX.Element  | null = <div className="zchat__header-icon zchat__header-icon--resize"/>;

    if (!chatConfig?.dnd) {
      icon = null;
    }
    return icon;
  }

  const determineConnectionStatus = () => {
    if (connectionStatus !== ConnectionStatus.Connected) {
      return connectionStatus;
    }

    if (agentProfile) {
      return ConnectionStatus.AgentConnected;
    }

    return ConnectionStatus.Connecting;
  }

  if (chatConfig?.automessages) {
    return (
          <header id="draggable" className={`zchat__header-data-protection zchat__header--${determineConnectionStatus()}`}>
            <div className="zchat__header-data-protection-avatars">
              <img src={`${domain}/images/avatars.png`} alt="Operators"/>
            </div>
            <div className="zchat__header-data-protection-text">
              <div className="zchat__header-data-protection-online">
                We're online and all ears
              </div>
              <div className="zchat__header-data-protection-reply">
                <span className="zchat__header-data-protection-reply-icon"></span>
                Usual reply time is under 2 minutes.
              </div>
            </div>
          </header>
    )
  } else if (chatConfig?.productName === 'web2app') {
    return (
        <header id="draggable" className={`zchat__header zchat__header--${determineConnectionStatus()} ${chatConfig?.theme === 'wta' ? `zchat__header-wta--${connectionStatus}`: ''}`}>
            {chatConfig?.theme === 'wta'
                ? <StatusesWta/>
                : <StatusesWeb2app/>
            }
          <div className="zchat__header-close" onClick={toggleChat} onTouchEnd={toggleChat}>

          </div>
        </header>
    )
  } else if (chatConfig?.afterSale) {
      return (
          <header id="draggable" className={`zchat__header zchat__header--${determineConnectionStatus()}`}>
              <StatusesAfterSale/>
          </header>
      )
  } else {
    return (
        <header id="draggable" className={`zchat__header zchat__header--${determineConnectionStatus()} ${chatConfig?.forBlog && '--blog'}`}>
          {getResizeIcon()}
          <Statuses/>
          <div className="zchat__header-close" onClick={toggleChat} onTouchEnd={toggleChat}>

          </div>
        </header>
    )
  }
}


export default Header;
