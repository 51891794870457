import {connect} from 'react-redux';
import {RootState} from '../../store';
import {setLoadingStatus} from '../../store/messages/actions';
import {ChatConfig} from '../../interfaces/zchat';
import {ConnectionStatus} from '../../constants';
import InputArea from './inputArea';
import {Messages} from '../../store/messages/types';

type StateType = {
  chatConfig: ChatConfig | null,
  connectionStatus: ConnectionStatus;
  messages: Messages | null;
}

const mapStateToProps = (state: RootState): StateType => ({
  chatConfig: state.chat.chatConfig,
  connectionStatus: state.chat.connectionStatus,
  messages: state.messages.messages,
});

const mapDispatchToProps = (dispatch: any) => ({
  setLoadingStatus: (status: boolean): void => {
    dispatch(setLoadingStatus(status));
  }
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InputArea);
