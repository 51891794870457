export const EmojiBlackList = [
  'Smiling Face with Smiling Eyes and Hand Covering Mouth',
  'Grinning Face with Star Eyes',
  'Grinning Face with One Large and One Small Eye',
  'Smiling Face with Smiling Eyes and Hand Covering Mouth',
  'Face with Finger Covering Closed Lips',
  'Face with One Eyebrow Raised',
  'Face with Open Mouth Vomiting',
  'Overheated Face',
  'Freezing Face',
  'Face with Uneven Eyes and Wavy Mouth',
  'Shocked Face with Exploding Head',
  'Face with Party Horn and Party Hat',
  'Face with Monocle',
  'Face with Pleading Eyes',
  'Yawning Face',
  'Serious Face with Symbols Covering Mouth',
  'Pinching Hand',
  'I Love You Hand Sign',
  'Palms Up Together',
  'Mechanical Arm',
  'Mechanical Leg',
  'Leg',
  'Foot',
  'Ear with Hearing Aid',
  'Brain',
  'Tooth',
  'Bone',
  'Child',
  'Adult',
  'Bearded Person',
  'Red Haired Man',
  'Curly Haired Man',
  'white_haired_man',
  'Bald Man',
  'Red Haired Woman',
  'Giraffe Face',
  'Llama',
  'Zebra Face',
  'Raccoon',
  'Service Dog',
  'Guide Dog',
  'Orangutan',
  'White Heart',
  'Brown Heart',
  'Orange Heart',
  'Goggles',
  'Lab Coat',
  'Firecracker',
  'Safety Vest',
  'Compass',
  'Mango',
  'Scarf',
  'Gloves',
  'Coat',
  'Socks',
  'Sari',
  'One-Piece Swimsuit',
  'Red Gift Envelope',
  'Coconut',
  'Briefs',
  'Shorts',
  'Brick',
  'Leafy Green',
  'Broccoli',
  'Garlic',
  'Onion',
  'Hiking Boot',
  'Flat Shoe',
  'Softball',
  'Ballet Shoes',
  'Pretzel',
  'Bagel',
  'Flying Disc',
  'Billed Cap',
  'Waffle',
  'Lacrosse Stick and Ball',
  'Hippopotamus',
  'Cut of Meat',
  'Hindu Temple',
  'Sandwich',
  'Hedgehog',
  'Diving Mask',
  'Falafel',
  'Sled',
  'Sloth',
  'Curling Stone',
  'Otter',
  'Skunk',
  'Yo-Yo',
  'Kite',
  'Bowl with Spoon',
  'Kangaroo',
  'Badger',
  'Butter',
  'Nazar Amulet',
  'Salt Shaker',
  'Canned Food',
  'Red Haired Person',
  'Curly Haired Woman',
  'Curly Haired Person',
  'Jigsaw Puzzle Piece',
  'White Haired Woman',
  'Teddy Bear',
  'White Haired Person',
  'Banjo',
  'Bald Woman',
  'Bald Person',
  'Blond Haired Woman',
  'Swan',
  'Older Adult',
  'Flamingo',
  'Peacock',
  'Moon Cake',
  'Parrot',
  'Dumpling',
  'Eritrea Flag',
  'Spool of Thread',
  'Fortune Cookie',
  'Ball of Yarn',
  'Takeout Box',
  'Ethiopia Flag',
  'Lobster',
  'European Union Flag',
  'Finland Flag',
  'Sauropod',
  'T-Rex',
  'Oyster',
  'Abacus',
  'Cupcake',
  'Deaf Person',
  'Pie',
  'Deaf Man',
  'Manual Wheelchair',
  'Motorized Wheelchair',
  'Deaf Woman',
  'Auto Rickshaw',
  'Skateboard',
  'Face Palm',
  'Man Facepalming',
  'Cricket',
  'Woman Facepalming',
  'Shrug',
  'Diya Lamp',
  'Man Shrugging',
  'Woman Shrugging',
  'Mosquito',
  'Health Worker',
  'Microbe',
  'Student',
  'Teacher',
  'Cup with Straw',
  'Beverage Box',
  'Mate Drink',
  'Judge',
  'Ice Cube',
  'Chopsticks',
  'Farmer',
  'Cook',
  'Parachute',
  'Mechanic',
  'Factory Worker',
  'Receipt',
  'Office Worker',
  'Flying Saucer',
  'Scientist',
  'Luggage',
  'Technologist',
  'Singer',
  'Artist',
  'Pilot',
  'Astronaut',
  'Firefighter',
  'Person with Headscarf',
  'Breast-Feeding',
  'Superhero',
  'Male Superhero',
  'Ringed Planet',
  'Female Superhero',
  'Axe',
  'Supervillain',
  'Male Supervillain',
  'Female Supervillain',
  'Large Orange Circle',
  'Mage',
  'Large Yellow Circle',
  'Male Mage',
  'Large Green Circle',
  'Female Mage',
  'Fairy',
  'Large Purple Circle',
  'Male Fairy',
  'Large Brown Circle',
  'Female Fairy',
  'Vampire',
  'Male Vampire',
  'Female Vampire',
  'Large Red Square',
  'Merperson',
  'Large Orange Square',
  'Merman',
  'Large Yellow Square',
  'Mermaid',
  'Probing Cane',
  'Large Green Square',
  'Large Blue Square',
  'Elf',
  'Male Elf',
  'Large Purple Square',
  'Female Elf',
  'Toolbox',
  'Large Brown Square',
  'Magnet',
  'Genie',
  'Male Genie',
  'Test Tube',
  'Female Genie',
  'Zombie',
  'Petri Dish',
  'Dna Double Helix',
  'Male Zombie',
  'Female Zombie',
  'Sudan Flag',
  'Drop of Blood',
  'Sweden Flag',
  'Singapore Flag',
  'Adhesive Bandage',
  'St. Helena Flag',
  'Stethoscope',
  'Standing Person',
  'Man Standing',
  'Chair',
  'Woman Standing',
  'Kneeling Person',
  'Man Kneeling',
  'Woman Kneeling',
  'Razor',
  'Person with Probing Cane',
  'Lotion Bottle',
  'Safety Pin',
  'Man with Probing Cane',
  'Broom',
  'Woman with Probing Cane',
  'Basket',
  'Person in Motorized Wheelchair',
  'Man in Motorized Wheelchair',
  'Roll of Paper',
  'Woman in Motorized Wheelchair',
  'Bar of Soap',
  'Sponge',
  'Person in Manual Wheelchair',
  'Fire Extinguisher',
  'Man in Manual Wheelchair',
  'Woman in Manual Wheelchair',
  'Person in Steamy Room',
  'Man in Steamy Room',
  'Woman in Steamy Room',
  'Person Climbing',
  'Man Climbing',
  'Woman Climbing',
  'Wallis & Futuna Flag',
  'Samoa Flag',
  'Yemen Flag',
  'Wales Flag',
  'Person in Lotus Position',
  'Man in Lotus Position',
  'Woman in Lotus Position',
  'People Holding Hands',
  'Smiling Face with Smiling Eyes and Three Hearts',
  'Scotland Flag',
  'England Flag',
  'Pirate Flag'
]
