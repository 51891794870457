export interface TimeConverterServiceInterface {
  getLocalTime(timestamp: number, format: Intl.DateTimeFormatOptions): string;

  getAMPM(timestamp: number, format: Intl.DateTimeFormatOptions): string;
}

export class TimeConverterService implements TimeConverterServiceInterface {
  dateFormat: {[key: string]: Intl.DateTimeFormatOptions};

  constructor() {
    this.dateFormat = {
      MDDYYYY: {month: 'short', day: 'numeric', year: 'numeric'},
      MDDhha: {month: 'short', day: 'numeric', hour: 'numeric', hour12: true},
      hhmma: {hour: '2-digit', minute: 'numeric', hour12: true}
    }
  }

  public getLocalTime(timestamp = 0, format: Intl.DateTimeFormatOptions = this.dateFormat.MDDYYYY): string {
    return new Date(timestamp * 1000).toLocaleString([], format);
  }

  public getAMPM(timestamp = 0, format: Intl.DateTimeFormatOptions = this.dateFormat.hhmma): string {
    if (!timestamp) {
      return '';
    }

    let date = this.getLocalTime(timestamp, format);
    return date.replace('AM', 'am').replace('PM', 'pm');
  }
}

export const TimeConverter = new TimeConverterService();
