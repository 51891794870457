export enum ConnectionStatus {
  Disconnected = 'disconnected',
  Connecting = 'connecting',
  Connected = 'connected',
  AgentConnected = 'agentConnected',
  NoInternetConnection = 'noInternetConnection',
  SessionClose = 'sessionClose'
}

export const AdaptiveBreakpoint = 480;
