export default class JwtParser {
  _token: string;

  constructor(token: string) {
    this._token = token;
  }

  get token() {
    return this._token;
  }

  get claims() {
    return this.parseJwt();
  }

  public parseJwt() {
    if (!this._token.length) {
      return {};
    }

    var base64Url = this._token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  public isExpired(): boolean {
    let now = (new Date()).getTime();
    let tokenExp = this.claims.exp * 1000;

    return now >= tokenExp;
  }
}
