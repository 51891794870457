import {ProductNames} from './configs';
import {ProductName} from '../interfaces/zchat';

declare var ENVIRONMENT_RESOURCE_PREFIX: string;

export const DomainName = {
  clario: 'clario.co',
  mackeeper: 'mackeeper.com',
  zoomsupport: 'mackeeper.com',
  mkSoft: 'mackeeper.com',
  web2app: 'clario.co',
  goinvisible: 'goinvisible.io',
}

export const getCloudFrontDomain = (productName: ProductName = ProductNames.clario): string => {
  return `https://zchat.account.${ENVIRONMENT_RESOURCE_PREFIX}${DomainName[productName]}`;
};


