export function checkMessageLength(next: (context: string) => void, context: string): void {
  // @ts-ignore
  const {messageMinLength, messageMaxLength} = this.chatConfig;

  if (!context.length) {
    return;
  }

  if (context.length < messageMinLength) {
    return;
  }

  if (messageMaxLength && context.length > messageMaxLength) {
    return;
  }

  return next(context);
}
