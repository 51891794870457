import React, {FunctionComponent} from 'react';

const NoInternet: FunctionComponent = () => {
  return (
    <div className={`zchat__status`}>
      <div className='zchat__status-icon zchat__status-icon--noInternetConnection'/>
      <p className='zchat__status-text'>No internet connection, please check your network</p>
    </div>
  );
}

export default NoInternet;
