import getLandName from "../../foundations/utils/getLandName";

export type SuggestionsBlogProductConfigType = {
  id: string,
  text: string,
  event?: string,
};



export type SuggestionsConfigType = Array<{
  [key: string]: Array<SuggestionsBlogProductConfigType>
}>

export let SuggestionsBlogConfig: Array<SuggestionsBlogProductConfigType>;

SuggestionsBlogConfig = [
    {
      id: 'check_my_mac_security',
      text: 'Check my Mac security',
      event: '//check_my_mac_security'
    },
    {
      id: 'start_my_free_mac_checkup',
      text: 'Start my free Mac checkup',
      event: '//start_my_free_mac_checkup'
    },
    {
      id: 'fix_my_issue_mentioned_in_article',
      text: 'Fix my issue mentioned in article',
      event: '//fix_my_issue_mentioned_in_article'
    },
    {
      id: 'tell_me_more_about_mk',
      text: 'Tell me more about MacKeeper',
      event: '//tell_me_more_about_macKeeper'
    }
  ]
