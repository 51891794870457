import {StorageState} from '../services/storageStateManager';
import objectOmitNull from './objectOmitNull';

declare var RELEASE_ID: string;

type ClientInfo = {
  os?: string,
  browser?: string,
  lang?: string,
  zchat?: string,
  clientId?: string,
  url?: string
}

export default function getClientInfo(): ClientInfo {
  let info = {
    os: `${navigator.platform} ${(navigator as any).oscpu ? ' / ' + (navigator as any).oscpu : ''}`,
    browser: navigator.userAgent,
    lang: navigator.language,
    zchat: RELEASE_ID,
    clientId: StorageState.clientId,
    url: window.location.href
  }

  return objectOmitNull(info)
}
