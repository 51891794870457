import {ThemeNames} from '../themes';
import {Theme} from '../interfaces/themes';
import {ProductNames} from '../constants';

export const clarioConfig = {
  chatConfig: {
    theme: (ThemeNames.clario as Theme),
    productName: ProductNames.clario,
  },
  roomConfig: {
    type: 'ClarioWeb',
  }
};
