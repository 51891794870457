import {connect} from 'react-redux';
import {setLoadingStatus} from '../../store/messages/actions';
import {RootState} from '../../store'
import {setAgentTypingStatus} from '../../store/agent/actions';
import {Messages} from '../../store/messages/types';
import MessageList from './messageList';
import {ChatConfig} from "../../interfaces/zchat";
import {ConnectionStatus} from "../../constants";

type StateType = {
  chatConfig: ChatConfig | null,
  connectionStatus: ConnectionStatus;
  agentTyping: boolean,
  isOpen: boolean,
  messages: Messages | null;
  loading: boolean;
}

const mapStateToProps = (state: RootState): StateType => ({
  chatConfig: state.chat.chatConfig,
  connectionStatus: state.chat.connectionStatus,
  isOpen: state.chat.isOpen,
  agentTyping: state.agent.agentTyping,
  messages: state.messages.messages,
  loading: state.messages.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAgentTypingStatus: (status: boolean): void => {
    dispatch(setAgentTypingStatus(status));
  },
  setLoadingStatus: (status: boolean): void => {
    dispatch(setLoadingStatus(status));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageList);
