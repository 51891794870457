import * as Sentry from '@sentry/browser';
import {NodeEnv} from '../../../constants';
import {SentryServiceInterface, ErrorType} from './types';
import {Severity} from '@sentry/types/dist/severity';

declare var NODE_ENV: string;
declare var RELEASE_ID: string;
declare var SENTRY_DSN: string;

export class SentryService implements SentryServiceInterface {
  public init(): void {
    if (NODE_ENV == NodeEnv.production) {
      try {
        Sentry.init({
          dsn: SENTRY_DSN,
          release: RELEASE_ID,
          maxBreadcrumbs: 50,
          environment: NODE_ENV,
          attachStacktrace: true,
        });
      } catch (error) {
        console.log(`Error while Sentry init ${error.message}`, error);
      }
    }
  }

  public determineErrorLevelForSentry(error: ErrorType): Severity {
    switch (error.status) {
      case 400:
      case 500:
        return Severity.Error;
      default:
        return Severity.Info;
    }
  }
}

export const SentryBrowser = new SentryService();
