import {connect} from 'react-redux';
import SuggestionsNew from "./suggestionsNew";
import {ChatConfig} from "../../interfaces/zchat";
import {RootState} from "../../store";

type StateType = {
    chatConfig: ChatConfig | null,
}

const mapStateToProps = (state: RootState): StateType => ({
    chatConfig: state.chat.chatConfig,
});

export default connect(
    mapStateToProps,
)(SuggestionsNew);
