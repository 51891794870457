import React, {Fragment, FunctionComponent, useState} from 'react';
import {useZchatContext} from '../zchatUI';
import {StorageState} from '../../foundations/services/storageStateManager';
import InputArea from "../inputArea/inputArea";
import {MessageTypes} from '../../constants';
import {ChatConfig} from "../../interfaces/zchat";
import {SuggestionsNewProductConfigType} from "./suggestionsNewConfig";
import getLandName from "../../foundations/utils/getLandName";

type Props = {
  chatConfig?: ChatConfig | null,
  suggestions: Array<SuggestionsNewProductConfigType>,
}

const SuggestionsNewList: FunctionComponent<Props> = ({suggestions, chatConfig}) => {
  const {chatClient, messageMiddleware, messageQueueService} = useZchatContext();
  const isSuggestionsOpen = StorageState.isSuggestionsOpen;
  const roomId = StorageState.roomId;
  const {toggleChat} = useZchatContext();

  const [isOpened, setOpen] = useState(true);
  const [message, setMessage] = useState('');
  const [currentEvent, setEvent] = useState('');
  const [isOther, setOther] = useState(false);

  if (!chatClient) {
    return null;
  }

  const handleSuggestionsClose = () => {
    setOpen(false);
    StorageState.setOpenSuggestions();
  }

  const handleChooseSuggestion = (event: any, message: string, customEvent?: string) => {
    setMessage(message);
    message === 'Other' || message === 'Custom request' ? setOther(true) : setOther(false);
    customEvent && setEvent(customEvent);
    // if (message === 'Other' && getLandName('mackeeper') === '335') {
    //   messageQueueService?.sendMessage({message: '//custom_issue', messageType: MessageTypes.comment});
    // }
  }


  const handleSendSuggestion = (event: any, message: string) => {
    handleSuggestionsClose();

    if (message === 'Other') return;

    if (message !== 'Custom message') {
      messageMiddleware?.sendMessage(message);
    }
    messageQueueService?.sendMessage({message: currentEvent, messageType: MessageTypes.comment});

    if (chatConfig?.productName === 'web2app') {
      messageQueueService?.sendMessage({message: window.location.href.split('?')[0], messageType: MessageTypes.comment});

      if (StorageState.plateText && StorageState.plateText === 'Solution here. Just click.') {
        messageQueueService?.sendMessage({message: '//solution_here_just click', messageType: MessageTypes.comment});
      } else if (StorageState.plateText && StorageState.plateText === 'Hit me up!') {
        messageQueueService?.sendMessage({message: '//hit_me_up!', messageType: MessageTypes.comment});
      }
    }
  }

  return (
      <>
        {!isSuggestionsOpen && !roomId && isOpened &&
          <div className='zchat__suggestions-wrapper'>
            <header id="draggable" className='zchat__header --suggestions'>
              <div className="zchat__header-close" onClick={toggleChat} onTouchEnd={toggleChat}>

              </div>
              <div className="zchat__header-text">
                {
                    chatConfig?.productName === 'web2app' && chatConfig?.theme === 'wta'
                    ? 'Please, specify your issue'
                    : 'Please, specify your issue.'
                }
              </div>
            </header>

            <div className='zchat__suggestions-list'>
              {suggestions && suggestions.length &&
                suggestions.map((suggestion) => {
                  return (
                    <Fragment key={suggestion.id}>
                      {suggestion.text === 'Other' || suggestion.text === 'Custom request' && isOther ? <InputArea chatConfig={chatConfig} isSuggestionsInput={true} setSuggestionsClose={handleSuggestionsClose}/> :
                        <div className={`zchat__suggestion-item ${suggestion.text === message ? 'active' : ''}`}
                             key={suggestion.id}
                             onClick={(event) => handleChooseSuggestion(event, suggestion.text, suggestion.event)}
                        >
                          {suggestion.text}
                        </div>
                      }
                    </Fragment>
                  )
                })
              }
            </div>

            {chatConfig?.theme === 'wta' &&
                <InputArea chatConfig={chatConfig} isSuggestionsInput={true} setSuggestionsClose={handleSuggestionsClose}/>
            }
            {!isOther && message &&
              <button className="zchat__suggestion-submit"
                      disabled={!message}
                      onClick={(event) => handleSendSuggestion(event, message)}
              >
                Submit
              </button>
            }
          </div>
        }
      </>
  );
}

export default SuggestionsNewList;
