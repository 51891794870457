import {ADD_MESSAGE, MessagesActionTypes, MessagesState, SET_HISTORY, SET_LOADING_STATUS, SET_READ_MESSAGES_STATE} from './types'
import {addMessageStatus} from './actions';
import {CLOSE_CHAT} from '../chat/types';

const initialState: MessagesState = {
  messages: null,
  loading: false,
}

export function messagesReducer(state = initialState, action: MessagesActionTypes): MessagesState {
  switch (action.type) {
    case SET_HISTORY:
      return {
        ...state,
        messages: action.payload,
      }
    case ADD_MESSAGE:
      if (!state.messages) {
        return {
          ...state,
          messages: [action.payload],
        }
      }

      const alreadyExistsMessage = state.messages.find(message => action.payload.uid === message.uid);

      if (alreadyExistsMessage) {
        return {
          ...state
        }
      }

      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    case SET_READ_MESSAGES_STATE:
      let processedMessages = null;
      if (state.messages != null) {
        processedMessages = state.messages.map(message => addMessageStatus(message, true));
      }

      return {
        ...state,
        messages: processedMessages
      }
    case SET_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload.status,
      }
    case CLOSE_CHAT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
