import {Config} from '../interfaces/zchat';
import {API_CRM_AUTHORIZE_MK, API_CRM_GET_REFRESH_TOKEN_MK} from '../constants/api';
import handleResponse from '../foundations/utils/handleResponse';
import CommonClient from './commonClient';
import {AuthorizeResponse} from '../interfaces/api';
import {getRequestHeaders} from '../foundations/utils/authHeader';
import {EventEmitter} from 'events';

declare var API_KEY_MK: string;
declare var API_KEY_ZOOM: string;

export default class MKClient extends CommonClient {
  private static instance: MKClient;

  constructor(config: Config, eventEmitter: EventEmitter) {
    super(config, eventEmitter);
  }

  // Authorize method with exponential backoff retry
  public async authorize(): Promise<void> {
    return await this.retryableFetch((this.authorizeApiCall).bind(this));
  }

  private async authorizeApiCall(): Promise<AuthorizeResponse> {
    const requestBody = {
      mac: this.roomConfig.mac || '',
      sid: this.roomConfig.sid || await MKClient.getDeviceId(),
      api_key: this.chatConfig.productName === 'zoomsupport' ? API_KEY_ZOOM : API_KEY_MK,
      email: this.roomConfig.email,
    };

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(),
      credentials: 'include',
      body: JSON.stringify(requestBody)
    };

    return fetch(API_CRM_AUTHORIZE_MK, requestOptions)
      .then(handleResponse)
      .then(this.processingAuthorizeResponse.bind(this))
      .catch((error) => this.handleError(error, API_CRM_AUTHORIZE_MK, 'auth', {...this.chatConfig, ...this.roomConfig}));
  }

  public async getRefreshTokenApiCall(): Promise<void> {
    const getRefreshTokenApiLink = `${API_CRM_GET_REFRESH_TOKEN_MK}/${this.roomId}/`;
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
    };

    return fetch(getRefreshTokenApiLink, requestOptions)
      .then(handleResponse)
      .then(resp => Promise.resolve(resp))
      .catch(error => this.handleError(error, getRefreshTokenApiLink, 'refresh-token'));
  }

  public static getInstance(config: Config, eventEmitter: EventEmitter): MKClient {
    if (!MKClient.instance) {
      MKClient.instance = new MKClient(config, eventEmitter);
    }

    return MKClient.instance;
  }
}
