export default function getBrowserName(): string {
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var nameOffset, verOffset;

// In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    return 'Opera';
  }

// In MSIE, the true version is after "MSIE" in userAgent
  if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    return 'Microsoft Internet Explorer';
  }

// In Chrome, the true version is after "Chrome"
  if ((verOffset = nAgt.indexOf('Chrome')) !== -1 || (verOffset = nAgt.indexOf('CriOS')) !== -1) {
    return 'Chrome';
  }

// In Firefox, the true version is after "Firefox"
  if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    return 'Firefox';
  }

// In Safari, the true version is after "Safari" or after "Version"
  if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    return 'Safari';
  }

// In most other browsers, "name/version" is at the end of userAgent
  if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      return navigator.appName;
    }
  }

  return browserName;
}
