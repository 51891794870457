import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Message from '../message';
import {MessageInterface, Messages, MessageType} from '../../store/messages/types';
import AgentTyping from '../agentTyping';
import Events from '../../constants/events';
import {useZchatContext} from '../zchatUI';
import {usePrevious} from '../../foundations/hooks';
import Loader from '../loader';
import {ChatConfig} from "../../interfaces/zchat";
import {ConnectionStatus, MessageTypes} from "../../constants";
import {StorageState} from "../../foundations/services/storageStateManager";
import getClientInfo from "../../foundations/utils/getClientInfo";
import getOsName from "../../foundations/utils/getOsName";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import AgentConnecting from "../agentConnecting";
import SuggestionsBlog from "../suggestionsBlog";
import SuggestionsSite from "../suggestionsSite";

type Props = {
  chatConfig?: ChatConfig | null,
  connectionStatus?: ConnectionStatus,
  messages: Messages | null;
  isOpen: boolean,
  agentTyping: boolean;
  loading: boolean;
  setAgentTypingStatus: (status: boolean) => void;
  setLoadingStatus: (status: boolean) => void;
}

const MessageList: FunctionComponent<Props> = ({chatConfig, messages, isOpen, agentTyping, loading, connectionStatus, setAgentTypingStatus, setLoadingStatus}) => {
  const {messageMiddleware, messageQueueService} = useZchatContext();
  const messagesListRef = useRef<HTMLDivElement>(null);
  const {eventEmitter} = useZchatContext();
  const prevMessages = usePrevious(messages);
  const isAutomessagesChosen = StorageState.isAutomessagesChosen;
  const isStartMessageChosen = StorageState.isStartMessageChosen;
  const isSentCustomMessage = StorageState.isSentCustomMessage;
  const [isAutomessagesOptionsShown, setShowAutomessagesOptions] = useState(false);
  const [isAutomessagesExtendedShown, setShowAutomessagesExtended] = useState(false);
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);

  var timer: any = null;

  let date = new Date();

  const startMessage: MessageInterface = {
    id: 'automessage_default',
    uid: '',
    type: 'outgoing',
    user: '',
    body: chatConfig?.automessagesData?.startMessage || 'Hi there and welcome to Clario. Have you ever had your passwords or any other private data hacked?',
    datetime: '',
    timestamp: +date/1000,
  }

  const startMessagesWeb2app: Array<MessageInterface> = [
    {
      id: 'automessage_web2app_1',
      uid: '',
      type: 'outgoing',
      user: '',
      body: 'Hey! I\'m your Clario anti-spy expert. Nice to have you here!',
      datetime: '',
      timestamp: +date/1000,
    },
    {
      id: 'automessage_web2app_2',
      uid: '',
      type: 'outgoing',
      user: '',
      body: ' Got any questions about the survey results? Any other spying concerns?',
      datetime: '',
      timestamp: +date/1000,
    },
    {
      id: 'automessage_web2app_3',
      uid: '',
      type: 'outgoing',
      user: '',
      body: 'Ready to dive deeper into that?',
      datetime: '',
      timestamp: +date/1000,
    }
  ];

  const addAutoScroll = () => {
    if (messagesListRef && messagesListRef.current) {
      const observer = new MutationObserver(() => {
        (messagesListRef.current as HTMLDivElement).scroll({
          top: (messagesListRef.current as HTMLDivElement).scrollHeight,
          behavior: 'smooth',
        });

        // Set an artificial delay - so that the loader does not disappear very quickly and there is no blinking effect
        timer = setTimeout(() => setLoadingStatus(false), 1000);
      });

      observer.observe(messagesListRef.current, {
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }

  useEffect(() => {
    subscribeToAgentTypingEvents();
    return () => {
      setLoadingStatus(false);
      unsubscribeToAgentTypingEvents()
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    subscribeToAutoMessagesEvents();
  }, []);

  useEffect(() => {
    if (!prevMessages && messages && messages.length) {
      setLoadingStatus(true);
    }

    addAutoScroll();
  }, [messages, messagesListRef.current?.offsetHeight]);

  const subscribeToAgentTypingEvents = () => {
    eventEmitter?.on(Events.onAgentTypingMessage, function () {
      setAgentTypingStatus(true);
    });

    eventEmitter?.on(Events.onAgentUnTypingMessage, function () {
      setAgentTypingStatus(false);
    });
  }

  const subscribeToAutoMessagesEvents = () => {
    eventEmitter?.on(Events.onShowAutomessagesOptions, () => {
      setShowAutomessagesOptions(true);
    });

    eventEmitter?.on(Events.onShowAutomessagesExtended, () => {
      setShowAutomessagesExtended(true);
    });
  }


  const unsubscribeToAgentTypingEvents = () => {
    eventEmitter?.off(Events.onAgentTypingMessage, function () {
      setAgentTypingStatus(true);
    });
    eventEmitter?.off(Events.onAgentUnTypingMessage, function () {
      setAgentTypingStatus(false);
    });
  }

  const handleAutomessageSelect = (value: string) => {
    let event;
    if (value === 'yes') {
      event = '//user_clicked_yes';
    } else if (value === 'no') {
      event = '//user_clicked_no';
    } else {
      event = '//user_clicked_not_sure';
    }

    messageQueueService?.sendMessage({message: event, messageType: MessageTypes.comment});

    StorageState.setAutomessagesChosen();
  }

  const handleAgentAutomessageSelect = (message: string) => {
    let event;
    switch (message) {
      case 'Yes':
        event = '//yes'
        break;
      case 'No':
        event = '//no'
        break
      case 'Not sure':
        event = '//not_sure'
        break
      case 'Expert analysis of my concerns':
        event = '//expert_analysis_of_my_concerns'
        break
      case 'Expert view on my current situation':
        event = '//expert_view_on_my_current_situation'
        break
      case 'I need a solution now, show me the details':
        event = '//i_need_a_solution_now_show_me_the_details'
        break
      case 'I am ready to start my protection, tell me how':
        event = '//I_am_ready_to_start_my_protection_tell_me_how'
        break
    }

    messageMiddleware?.sendMessage(message);
    event && messageQueueService?.sendMessage({message: event, messageType: MessageTypes.comment});

    setShowAutomessagesOptions(false);
    setShowAutomessagesExtended(false);
  }

  const handleStartMessageSelect = (value: string) => {
    let event;
    let message;
    let status = chatConfig?.surveyStatus || 'unknown';
    let osName = `//platform_${getOsName()}`;
    let surveyProblems = chatConfig?.surveyProblems;
    let isOpenWithBubble = StorageState.isOpenWithBubbleState;

    if (value === 'yes') {
      event = '//Surveylp_chat_started_yes';
      message = 'You answered: YES';
    } else {
      event = '//Surveylp_chat_started_no';
      message = 'You answered: NO';
    }


    if (!messages || !messages.length) {
      setLoadingStatus?.(true);
    }

    messageMiddleware?.sendMessage(message);

    isOpenWithBubble ? messageQueueService?.sendMessage({message: '//Surveylp_chat_started_icon', messageType: MessageTypes.comment}) : messageQueueService?.sendMessage({message: '//Surveylp_chat_started_intro', messageType: MessageTypes.comment});

    messageQueueService?.sendMessage({message: event, messageType: MessageTypes.comment});
    messageQueueService?.sendMessage({message: osName, messageType: MessageTypes.comment});
    messageQueueService?.sendMessage({message: `//status_${status}`, messageType: MessageTypes.comment});

    surveyProblems?.forEach((problem) => {
      messageQueueService?.sendMessage({message: `//problem_${problem}`, messageType: MessageTypes.comment});
    });

    StorageState.setStartMessageChosen();
  }

  if (chatConfig?.automessages) {
    return (
        <div className='zchat__messages-wrapper zchat__messages-wrapper-data-protection'>
          <div className='zchat__message-list zchat__message-list-data-protection' ref={messagesListRef}>
            <Message
                key={startMessage.uid}
                message={startMessage}
            />

            {!isAutomessagesChosen && !isSentCustomMessage &&
              <div className="zchat__automessages">
                <div className="zchat__automessages-btn" data-value={'yes'} onClick={() => handleAutomessageSelect('yes')}>
                  Yes
                  <span className="zchat__automessages-btn-icon"></span>
                </div>
                <div className="zchat__automessages-btn" data-value={'no'} onClick={() => handleAutomessageSelect('no')}>
                  No
                  <span className="zchat__automessages-btn-icon"></span>
                </div>
                <div className="zchat__automessages-btn" data-value={'notSure'} onClick={() => handleAutomessageSelect('notSure')}>
                  Not sure, let's check
                  <span className="zchat__automessages-btn-icon"></span>
                </div>
              </div>
            }

            {messages && messages.map((message) => (
                <Message
                    key={message.uid}
                    message={message}
                />
            ))
            }

            {agentTyping && <AgentTyping/>}
          </div>

          {loading && <Loader className={`zchat__loader--messages`}/>}
        </div>
    );
  }

  if (!isStartMessageChosen && chatConfig?.productName === 'web2app' && chatConfig?.web2appZChatIteration === 'it_1') {
    return (
        <div className='zchat__messages-wrapper --web2app'>
          <div className="zchat__start-message">
            <div className="zchat__start-message-header">
              Hey! I'm your Clario anti-spy expert. Nice to have you here! <br/> <br/>
              Got any questions about the survey results? Any other spying concerns? <br/> <br/>
              Ready to dive deeper into that?
            </div>
            <div className="zchat__start-message-buttons">
              <div className="zchat__start-message-button js-start-message js-start-message-yes" onClick={() => handleStartMessageSelect('yes')}>Yes</div>
              <div className="zchat__start-message-button js-start-message js-start-message-no" onClick={() => handleStartMessageSelect('no')}>No</div>
            </div>
          </div>
        </div>
    )
  }

  if (isStartMessageChosen && chatConfig?.productName === 'web2app' && chatConfig?.web2appZChatIteration === 'it_1') {
    return (
        <div className='zchat__messages-wrapper'>
          <div className='zchat__message-list' ref={messagesListRef}>
            {startMessagesWeb2app && startMessagesWeb2app.map((message) => (
                <Message
                    key={message.id}
                    message={message}
                />
            ))
            }

            {messages && messages.map((message) => (
                <Message
                    key={message.uid}
                    message={message}
                />
            ))
            }

            {agentTyping && <AgentTyping/>}
          </div>

          {loading && <Loader className={`zchat__loader--messages`}/>}
        </div>
    );
  }

  if ((!messages || !messages.length) && chatConfig?.forBlog && !chatConfig.suggestions) {
    return (
        <div className='zchat__messages-wrapper'>
          <SuggestionsBlog/>
        </div>
    );
  }

  if ((!messages || !messages.length) && chatConfig?.forSite && !chatConfig.suggestions) {
    return (
        <div className='zchat__messages-wrapper'>
          <SuggestionsSite/>
        </div>
    );
  }

  if (!messages || !messages.length) {
    return (
        <div className='zchat__messages-wrapper'>
          {loading && <Loader className={`zchat__loader--messages`}/>}
          <div className='zchat__no-messages'/>
        </div>
    );
  }

  if (chatConfig?.productName === 'web2app' && chatConfig.web2appZChatIteration === 'it_2') {
    return (
        <div className='zchat__messages-wrapper --web2app --it_2'>
          <div className='zchat__message-list' ref={messagesListRef}>
            {messages && messages.map((message) => (
                <Message
                    key={message.uid}
                    message={message}
                />
            ))
            }

            {isAutomessagesOptionsShown &&
            <>
              <div className={"zchat__automessages-title"}>Choose your answer</div>
              <div className="zchat__automessages --web2app --it_2">
                <div className="zchat__automessages-btn" data-message={'Yes'} onClick={() => handleAgentAutomessageSelect('Yes')}>
                  Yes
                </div>
                <div className="zchat__automessages-btn" data-message={'No'} onClick={() => handleAgentAutomessageSelect('No')}>
                  No
                </div>
                <div className="zchat__automessages-btn" data-message={'Not sure'} onClick={() => handleAgentAutomessageSelect('Not sure')}>
                  Not sure
                </div>
              </div>
            </>}

            {isAutomessagesExtendedShown &&
            <>
              <div className={"zchat__automessages-title"}>Choose your answer</div>
              <div className="zchat__automessages --web2app --it_2 --wide">
                <div className="zchat__automessages-btn --wide"
                     onClick={() => handleAgentAutomessageSelect('Expert analysis of my concerns')}>
                  Expert analysis of my concerns
                </div>
                <div className="zchat__automessages-btn --wide"
                     onClick={() => handleAgentAutomessageSelect('Expert view on my current situation')}>
                  Expert view on my current situation
                </div>
                <div className="zchat__automessages-btn --wide"
                     onClick={() => handleAgentAutomessageSelect('I need a solution now, show me the details')}>
                  I need a solution now, show me the details
                </div>
                <div className="zchat__automessages-btn --wide"
                     onClick={() => handleAgentAutomessageSelect('I am ready to start my protection, tell me how')}>
                  I am ready to start my protection, tell me how
                </div>
              </div>
            </>}


            {agentTyping && <AgentTyping/>}
          </div>
          {loading && <Loader className={`zchat__loader--messages`}/>}
        </div>
    );
  }

  if (chatConfig?.afterSale) {
    return (
        <div className={`zchat__messages-wrapper`}>
          <div className={`zchat__message-list zchat__message-list--aftersale`} ref={messagesListRef}>
            {messages.map((message) => (
                <Message
                    key={message.uid}
                    message={message}
                />
            ))
            }

            {agentProfile && agentTyping && <AgentTyping customName={'Joe'}/>}
            {!agentProfile && <AgentConnecting/>}
          </div>
          {loading && <Loader className={`zchat__loader--messages`}/>}
        </div>
    );
  }

  return (
      <div className={`zchat__messages-wrapper`}>
        <div className={`zchat__message-list`} ref={messagesListRef}>
          {messages.map((message) => (
              <Message
                  key={message.uid}
                  message={message}
              />
          ))
          }

          {agentTyping && <AgentTyping/>}
        </div>
        {loading && <Loader className={`zchat__loader--messages`}/>}
      </div>
  );
}
export default MessageList;
