import {ProductName} from '../interfaces/zchat';

export const ProductNames: {[key: string]: ProductName} = {
  clario: 'clario',
  mackeeper: 'mackeeper',
  zoomsupport: 'zoomsupport',
  mkSoft: 'mkSoft',
  web2app: 'web2app',
  goinvisible: 'goinvisible',
};


export const ChatRoomTypes = {
  clarioWeb: 'ClarioWeb',
  preRenewalEmail: 'PreRenewalEmail',
  findAndFixMKSite: 'FindAndFixMKSite',
  ZoomDiagnosticsMac: 'ZoomDiagnosticsMac',
  letsgotoRA: 'Letsgoto_RA'
}

export const InitConfigStorageKey = 'zchat_init_config'
