import {ThemeNames} from '../themes';
import {Theme} from '../interfaces/themes';
import {ProductNames} from '../constants';

export const web2appConfig = {
  chatConfig: {
    theme: (ThemeNames.web2app as Theme),
    productName: ProductNames.web2app,
  },
  roomConfig: {
    type: 'ClarioWeb',
  }
};
