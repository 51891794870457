import React, {FunctionComponent, useEffect, useState} from 'react';
import {Messages} from '../../store/messages/types';
import Tooltip from '../tooltip';
import {ChatConfig} from '../../interfaces/zchat';
import {getCloudFrontDomain, MessageTypes, StartChattingTooltipCopy, TooltipTypes} from '../../constants';
import {TooltipState} from '../../store/chat/types';
import {StorageState} from '../../foundations/services/storageStateManager';
import {useZchatContext} from "../zchatUI";
import * as events from "events";

type Props = {
  toggleChat: () => void;
  tooltipState: TooltipState,
  isOpen: boolean,
  messages: Messages | null,
  chatConfig: ChatConfig | null,
  setTooltipState: (state: TooltipState) => void,
}

const ChatBubble: FunctionComponent<Props> = ({toggleChat, tooltipState, isOpen, messages, chatConfig, setTooltipState}) => {
  const [unreadMessages, setUnreadMessage] = useState<Messages>([]);
  const [timeoutCallback, setTimeoutCallback] = useState<any>(null);
  const [tooltipTimeout, setTooltipTimeout] = useState(0);
  const [isPlateOpened, setPlateOpened] = useState(true);
  const [isScrolled, setUserScrolled] = useState(false);
  const [isScrolledToSecondScreen, setScrolledToSecondScreen] = useState(false);
  const [isNewPlateOpened, setNewPlateOpened] = useState(false);
  const [isPlateTextOpened, setPlateTextOpened] = useState(true);
  const [plateText, setPlateText] = useState('Solution here. Just click.');
  const [isPlateTextChanged, setPlateTextChanged] = useState(false);
  const isSuggestionsOpen = JSON.parse(localStorage?.getItem('zchat_state') || '{}').isSuggestionsOpen;
  const {messageQueueService} = useZchatContext();
  const scrollPoints = chatConfig?.scrollPoints;
  const plateTextVariations = chatConfig?.plateTextVariations;
  let domain = getCloudFrontDomain(chatConfig?.productName);

  scrollPoints && scrollPoints?.forEach((point, index) => {
    window.addEventListener("scroll", (e) => {
      e.stopPropagation();

      if (window.pageYOffset > point && !(scrollPoints[index + 1] && window.pageYOffset > scrollPoints[index + 1])) {
        !isPlateTextChanged && setPlateTextChanged(true);
        plateTextVariations && setPlateText(plateTextVariations[index]);
        chatConfig?.dynamicBubble && StorageState.setPlateText(plateText);
      }
    });
  });

  window.addEventListener("scroll", (e) => {
    if (scrollPoints && window.pageYOffset > scrollPoints[0]) {
      setScrolledToSecondScreen(true);
    }
    if (window.pageYOffset > 500) {
      setUserScrolled(true);
    }
  });


  useEffect(() => {
    processedMessagesByReadStatus();
  }, [messages]);

  useEffect(() => {
    processTooltipStateFromConfig();
  }, [chatConfig]);

  useEffect(() => {
    if (tooltipState.isOpen) {
      updateTooltipState(true, unreadMessages[unreadMessages.length - 1].body);
    }
  }, [unreadMessages]);

  useEffect(() => {
    if (!tooltipTimeout) {
      return;
    }

    setTimeoutCallback(setTimeout(() => {
      updateTooltipState(true, StartChattingTooltipCopy, TooltipTypes.startChatting);
    }, tooltipTimeout * 1000));
    return () => clearTimeout(timeoutCallback);
  }, [tooltipTimeout]);

  useEffect(() => {
    if (isOpen) {
      if (setTimeoutCallback != null) {
        clearTimeout(timeoutCallback);
      }
      updateTooltipState(false);
    }
  }, [isOpen]);

  const processedMessagesByReadStatus = () => {
    if (!messages) {
      return;
    }

    let unreadStatusMessages = messages.filter(message => !message.read);
    setUnreadMessage(unreadStatusMessages);
  }

  const updateTooltipState = (state: boolean, text = '', type = TooltipTypes.unreadMessage) => {
    setTooltipState({isOpen: state, text, type});
  }

  const processTooltipStateFromConfig = () => {
    if (!chatConfig?.startChattingTooltip) {
      return;
    }

    const {show, timeoutToShow, showNextTimeAfterClose} = chatConfig.startChattingTooltip;

    if (!show || !timeoutToShow || isOpen || (!showNextTimeAfterClose && StorageState.closeStartChattingTooltip)) {
      return;
    }

    setTooltipTimeout(timeoutToShow);
  }

  const handleHover = () => {
    if (unreadMessages.length) {
      updateTooltipState(true, unreadMessages[unreadMessages.length - 1].body, TooltipTypes.unreadMessage);
    }
  }

  const closeTextPlate = function (e: any) {
    e.stopPropagation();
    setPlateTextOpened(false);
  }

  const toggleChatWithPlate = function () {
    if (!StorageState.isOpenState && StorageState.roomId && chatConfig?.productName === 'web2app') {
      // messageQueueService?.sendMessage({message: '//Surveylp_chat_started_icon', messageType: MessageTypes.comment});
    }

    toggleChat();
    setPlateOpened(false);
    setPlateTextOpened(false);
    StorageState.setOpenChatWithBubble();
  }

  if (isScrolledToSecondScreen) {
    setTimeout(() => {
      setNewPlateOpened(true);
    }, 1000);
  }


  if (chatConfig?.dynamicBubble) {
    return (
        <>
          <div
              className={`zchat--web2app__bubble-container ${!isScrolledToSecondScreen ? 'zchat--web2app__bubble--hidden' : 'zchat--web2app__bubble--visible'} ${isPlateTextOpened && isNewPlateOpened && isScrolledToSecondScreen ? '--text-opened' : ''}`}
              onClick={toggleChatWithPlate}
          >
            <div
                className={`zchat--web2app__bubble zchat--web2app__bubble--${chatConfig?.theme} ${!chatConfig?.showBubble ? 'zchat--web2app__bubble--hide' : ''} ${chatConfig?.automessages ? 'zchat--web2app__bubble--data-protection' : ''}`}
                onMouseEnter={handleHover}
            >
              <i className='zchat--web2app__bubble-icon zchat--web2app__bubble-icon--messages'/>
              <div className="zchat--web2app__bubble-icon--dots">
                <span className="zchat--web2app__bubble-icon--dot green"> </span>
                <span className="zchat--web2app__bubble-icon--dot white"> </span>
                <span className="zchat--web2app__bubble-icon--dot purple"> </span>
              </div>
              {unreadMessages.length ? (
                  <>
                    <div className='zchat--web2app__unread-message-icon'>{unreadMessages.length}</div>
                  </>
              ) : null}
            </div>

            <div className={`zchat--web2app__bubble--text`}>
              <i className='zchat--web2app__bubble--text-close' onClick={closeTextPlate}/>
              {plateText}
            </div>

          </div>
          {tooltipState.isOpen && <Tooltip text={tooltipState.text || ''}/>}
        </>
    );
  }

  if (chatConfig?.productName === 'web2app' && chatConfig?.theme !== 'wta') {
    return (
        <>
          <div
              className={`zchat--web2app__bubble-container ${!isScrolledToSecondScreen ? 'zchat--web2app__bubble--hidden' : 'zchat--web2app__bubble--visible'}`}
              onClick={toggleChatWithPlate}
          >
            <div
                className={`zchat--web2app__bubble zchat--web2app__bubble--${chatConfig?.theme} ${!chatConfig?.showBubble ? 'zchat--web2app__bubble--hide' : ''} ${chatConfig?.automessages ? 'zchat--web2app__bubble--data-protection' : ''}`}
                onMouseEnter={handleHover}
            >
              <i className='zchat--web2app__bubble-icon zchat--web2app__bubble-icon--messages'/>
              <div className="zchat--web2app__bubble-icon--dots">
                <span className="zchat--web2app__bubble-icon--dot green"> </span>
                <span className="zchat--web2app__bubble-icon--dot white"> </span>
                <span className="zchat--web2app__bubble-icon--dot purple"> </span>
              </div>
              {unreadMessages.length ? (
                  <>
                    <div className='zchat--web2app__unread-message-icon'>{unreadMessages.length}</div>
                  </>
              ) : null}
            </div>

          </div>
          {tooltipState.isOpen && <Tooltip text={tooltipState.text || ''}/>}
        </>
    )
  }

  return (
      <>
        <div className="zchat__bubble-container">
          {!isOpen && !isSuggestionsOpen && isPlateOpened && (chatConfig?.suggestions || chatConfig?.plateTextNeeded) &&
              <div className={`zchat__bubble-plate zchat__bubble-plate--${chatConfig?.theme} ${isScrolled ? 'active' : ''}`}
                   onClick={toggleChatWithPlate}
              >
                {chatConfig?.plateText ? chatConfig?.plateText : 'Need help with your Mac issue right now?'}
                <div className="zchat__bubble-plate-close" onClick={(event) => {
                  setPlateOpened(false);
                  event.stopPropagation();
                }}/>
              </div>
          }
          {chatConfig?.automessages && isPlateOpened && !isOpen &&
              <>
                <div className={`zchat__bubble-plate zchat__bubble-plate-data-protection zchat__bubble-plate--${chatConfig?.theme} ${isScrolled ? 'active' : ''}`}
                     onClick={toggleChatWithPlate}
                >
                  <div className={'zchat__bubble-plate-data-protection-logo'}>

                  </div>
                  <div className="zchat__bubble-plate-data-protection-text">
                    {chatConfig?.plateText ? chatConfig?.plateText : 'Need help with your Mac issue right now?'}
                  </div>
                  <div className="zchat__bubble-plate-data-protection-close" onClick={(event) => {
                    setPlateOpened(false);
                    event.stopPropagation();
                  }}/>
                </div>
              </>
          }

          {chatConfig?.antiSpyPlate && isPlateOpened && !isOpen &&
              <div className={`zchat__bubble-plate zchat__bubble-plate--${chatConfig?.theme} --anti-spy-plate active`}
                   onClick={toggleChatWithPlate}
              >
                <div className="zchat__bubble-plate-avatar">
                  <img src={`${domain}/images/web2app-avatar.png`} alt="Operators"/>
                </div>
                <div className="zchat__bubble-plate-text">
                  {chatConfig?.plateText ? chatConfig?.plateText : 'Want to chat about support? I`m here to help you find your way.'}
                </div>
                <div className="zchat__bubble-plate-close" onClick={(event) => {
                  setPlateOpened(false);
                  event.stopPropagation();
                }}/>
              </div>
          }

          <div className={`zchat__bubble zchat__bubble--${chatConfig?.theme} ${isOpen ? 'zchat__bubble--open' : 'zchat__bubble--close'} ${!chatConfig?.showBubble ? 'zchat__bubble--hide' : ''} ${chatConfig?.automessages ? 'zchat__bubble--data-protection' : ''}`}
               onClick={() => {
                 if (chatConfig?.antiSpyPlate && isOpen) {
                   toggleChat();
                 } else {
                   toggleChatWithPlate();
                 }
               }}
               onMouseEnter={handleHover}
          >
            {isOpen ?
                <i className='zchat__bubble-icon zchat__bubble-icon--close'/>
                : (
                    <>
                      <i className='zchat__bubble-icon zchat__bubble-icon--messages' />
                      {unreadMessages.length ? (
                          <>
                            <div className='zchat__unread-message-icon'>{unreadMessages.length}</div>
                          </>
                      ) : null}
                    </>)}
          </div>

        </div>
        {tooltipState.isOpen && <Tooltip text={tooltipState.text || ''}/>}
      </>
  );
};

export default ChatBubble;
