import React, {FunctionComponent, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {ConnectionStatus} from '../../../constants';
import {RootState} from '../../../store';
import DisconnectedWta from './disconnectedWta';
import Connecting from './connecting';
import Connected from './connected';

const statusesWta: FunctionComponent = () => {
  const connectionStatus = useSelector((state: RootState) => state.chat.connectionStatus);
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);

  const determineConnectionStatus = () => {
    switch (connectionStatus) {
      case ConnectionStatus.Connected:
        return <Connected/>;
      case ConnectionStatus.AgentConnected:
        return <Connecting/>;
      case ConnectionStatus.Connecting:
        return <Connecting/>;
      default:
        return <DisconnectedWta/>;
    }
  }

  return (
    <Suspense fallback={null}>
      <>
        {determineConnectionStatus()}
      </>
    </Suspense>
  );
}

export default statusesWta;

