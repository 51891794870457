import {ConnectionStatus, TooltipTypes} from '../../constants';
import {ChatConfig} from '../../interfaces/zchat';

export const TOGGLE_CHAT = 'TOGGLE_CHAT';
export const OPEN_CHAT = 'OPEN_CHAT';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_CHAT_CONFIG = 'SET_CHAT_CONFIG';
export const CLOSE_CHAT = 'CLOSE_CHAT';
export const SET_TOOLTIP_STATE = 'SET_TOOLTIP_STATE';

export interface ChatState {
  isOpen: boolean,
  connectionStatus: ConnectionStatus,
  chatConfig: ChatConfig | null,
  tooltip: TooltipState,
}

export interface ToggleChatAction {
  type: typeof TOGGLE_CHAT
  payload: Record<string, never>
}

export interface OpenChatAction {
  type: typeof OPEN_CHAT
  payload: Record<string, never>
}

export interface SetConnectionStatusAction {
  type: typeof SET_CONNECTION_STATUS
  payload: {status: ConnectionStatus}
}

export interface SetChatConfigAction {
  type: typeof SET_CHAT_CONFIG
  payload: ChatConfig
}

export interface CloseChatAction {
  type: typeof CLOSE_CHAT
  payload: Record<string, never>
}

export interface SetTooltipStateAction {
  type: typeof SET_TOOLTIP_STATE
  payload: TooltipState
}

export type TooltipState = {
  isOpen: boolean,
  text?: string,
  type?: TooltipTypes,
}

export type ChatActionTypes = ToggleChatAction | SetConnectionStatusAction | SetChatConfigAction | CloseChatAction | SetTooltipStateAction | OpenChatAction;
