import {MessageInterface} from '../store/messages/types';

type XactionParserResult = {
  actionName: string,
  actionArguments?: {[key: string]: any}
}

export const XactionsNames = {
  agent_profile: 'agent_profile',
  info: 'info',
  ping: 'ping',
  close: 'close',
  hide: 'hide',
  scroll: 'scroll',
  show_checkout: 'show_checkout',
  download: 'download',
  show_auto_messages_options: 'show_auto_messages_options',
  show_auto_messages_extended: 'show_auto_messages_extended',
}

const XactionsList = [
  {
    name: XactionsNames.agent_profile,
    parsingFunc: parseAgentProfileXaction,
  },
  {
    name: XactionsNames.info,
    parsingFunc: parseInfoXaction,
  },
  {
    name: XactionsNames.ping,
    parsingFunc: parsePingXaction,
  },
  {
    name: XactionsNames.close,
    parsingFunc: parseCloseXaction,
  },
  {
    name: XactionsNames.hide,
    parsingFunc: parseHideXaction,
  },
  {
    name: XactionsNames.show_checkout,
    parsingFunc: parseShowCheckoutXaction,
  },
  {
    name: XactionsNames.scroll,
    parsingFunc: parseScrollXaction,
  },
  {
    name: XactionsNames.download,
    parsingFunc: parseDownloadXaction,
  },
  {
    name: XactionsNames.show_auto_messages_options,
    parsingFunc: parseShowAutoMessagesOptionsXaction,
  },
  {
    name: XactionsNames.show_auto_messages_extended,
    parsingFunc: parseShowAutoMessagesExtendedXaction,
  },
];

export function parseXaction(xaction: MessageInterface): XactionParserResult {
  let xactionParsedResult = {
    actionName: '',
  };

  for (let i = 0; i <= XactionsList.length; i++) {
    if (xaction.body.includes(XactionsList[i].name)) {
      xactionParsedResult = XactionsList[i].parsingFunc(xaction.body);
      break;
    }
  }

  return xactionParsedResult;
}

export function parseAgentProfileXaction(xactionBody: string): XactionParserResult {
  let agentProfileInfo = JSON.parse(xactionBody.split(XactionsNames.agent_profile)[1]);
  return {
    actionName: XactionsNames.agent_profile,
    actionArguments: agentProfileInfo,
  }
}

export function parsePingXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.ping);
}

export function parseInfoXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.info);
}

export function parseCloseXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.close);
}

export function parseHideXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.hide);
}

export function parseScrollXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.scroll);
}

export function parseShowCheckoutXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.show_checkout);
}

export function parseDownloadXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.download);
}

export function parseShowAutoMessagesOptionsXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.show_auto_messages_options);
}

export function parseShowAutoMessagesExtendedXaction(): XactionParserResult {
  return parseXactionWithoutParams(XactionsNames.show_auto_messages_extended);
}

export function parseXactionWithoutParams(name: string): XactionParserResult {
  return {
    actionName: name,
  }
}
