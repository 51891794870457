import {ADD_MESSAGE, MessageInterface, Messages, MessagesActionTypes, SET_HISTORY, SET_READ_MESSAGES_STATE, SET_LOADING_STATUS} from './types'
import {Dispatch} from 'react';
import {MessageTypes} from '../../constants';

export function setHistory(messages: Messages): MessagesActionTypes {
  let proceedMessages = messages.map((message: MessageInterface) => {
    return addMessageStatus(message, true);
  }).reverse();

  return {
    type: SET_HISTORY,
    payload: proceedMessages,
  }
}

export function addMessage(message: MessageInterface, messageType: string) {
  return (dispatch: Dispatch<MessagesActionTypes>, getState: () => any): void => {
    let proceedMessage;

    switch (messageType) {
      case MessageTypes.incoming:
        proceedMessage = addMessageStatus(message, true);
        break;
      case MessageTypes.outgoing:
        const openChatState = getState().chat.isOpen;
        proceedMessage = addMessageStatus(message, openChatState);
        break;
      default:
        proceedMessage = addMessageStatus(message, true);
    }

    dispatch({type: ADD_MESSAGE, payload: proceedMessage})
  }
}

export function addMessageStatus(message: MessageInterface, status: boolean): MessageInterface {
  return {...message, read: status};
}

export function setReadMessagesState(): MessagesActionTypes {
  return {
    type: SET_READ_MESSAGES_STATE,
    payload: {}
  }
}

export function setLoadingStatus(loadingStatus: boolean): MessagesActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload: {status: loadingStatus}
  }
}
