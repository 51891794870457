import React, {FunctionComponent, ReactElement} from 'react';

interface MessageTextInterface {
  children: Array<ReactElement> | any;
}

const MessageText: FunctionComponent<MessageTextInterface> = ({children}) => {
  const getContent = () => {
    if (!Array.isArray(children)) {
      return children;
    }

    return React.Children.map(children, (child) => {
      if (!child.props || !child.props.href) {
        return child;
      }
      return React.cloneElement(child, {'href': child.props.href.replace(/&amp;/g, '&')})
    })
  }

  return (
    <>
      {getContent()}
    </>
  );
}

export default MessageText;
