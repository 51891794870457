import React, {FunctionComponent, ReactNode} from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  style?: {[key: string]: string},
}

const Loader: FunctionComponent<Props> = ({children = null, className, style= undefined}) => {

  return (
    <div className={`zchat__loader ${className ? className : ''}`} style={style}>
      <div className='zchat__loader-wrapper'>
        <span className='zchat__loader-circle'/>
        <span className='zchat__loader-circle'/>
        <span className='zchat__loader-circle'/>
      </div>
      {children}
    </div>
  );
}

export default Loader;
