import React, {FunctionComponent} from 'react';
import InputArea from '../inputArea';
import MessageList from '../messageList';
import Header from '../header';
import SuggestionsNew from "../suggestionsNew";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {StorageState} from "../../foundations/services/storageStateManager";
import SuggestionsBlog from "../suggestionsBlog/suggestionsBlog";

const Chat: FunctionComponent = () => {
  const chatConfig = useSelector((state: RootState) => state.chat.chatConfig);
  const isSuggestionsOpen = StorageState.isSuggestionsOpen;

  return (
      <div className={`zchat__container box no-cursor ${chatConfig?.productName === 'web2app' ? '--web2app' : ''} ${chatConfig?.productName === 'web2app' && chatConfig?.theme === 'wta' ? '--wta' : ''} ${chatConfig?.suggestions && !isSuggestionsOpen ? '--suggestions' : ''} ${chatConfig?.automessages ? '--data-protection' : ''}`}>
        {chatConfig && chatConfig.suggestions  &&
            <SuggestionsNew/>
        }
        <Header/>
        <MessageList/>
        <InputArea/>
      </div>
  );

}

export default Chat;
