import React, {FunctionComponent} from 'react';

type Props = {
  className?: string
}

const DefaultAvatar: FunctionComponent<Props> = ({className = ''}) => {
  return (
    <div className={`zchat__agent-avatar zchat__agent-avatar--default ${className}`}/>
  )

}

export default React.memo(DefaultAvatar);
