import React, {FunctionComponent} from 'react';
import {SuggestionsSiteConfig} from './suggestionsSiteConfig';
import SuggestionsSiteList from './suggestionsSiteList';
import {ChatConfig} from "../../interfaces/zchat";

type props = {
    chatConfig: ChatConfig | null,
}

const SuggestionsSite: FunctionComponent<props> = ({chatConfig}) => {
  return (
    <div className={`zchat__suggestions --blog`}>
      <SuggestionsSiteList chatConfig={chatConfig} suggestions={SuggestionsSiteConfig}/>
    </div>
  )
}

export default SuggestionsSite;
