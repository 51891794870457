import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {MessageInterface} from '../../store/messages/types';
import parser from 'bbcode-to-react';
import {TimeConverter} from '../../foundations/services/timeConverter';
import {useZchatContext} from '../zchatUI';
import Events from '../../constants/events';
import MessageText from './messageText';
import {getCloudFrontDomain} from "../../constants";

type Props = {
  message: MessageInterface,
}

const Message: FunctionComponent<Props> = ({message}) => {
  const [messageText, setMessageText] = useState<string | ReactElement | null>(null);
  const {eventEmitter} = useZchatContext();


  useEffect(() => {
    parseBbCode();
  }, [message]);



  // Need to prevents xss through bbcode
  // parser will clear xss
  const parseBbCode = () => {
    setMessageText((parser.toReact(message.body) as ReactElement));
  }

  if (!messageText || typeof messageText == 'string' && !messageText.length) {
    return null;
  }

  const handleMessageClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLAnchorElement;
    // Handle user clicked on the link
    if ((target).tagName.toLowerCase() === 'a') {
      eventEmitter?.emit(Events.onUserClickLink, target.href);
    }

    // Handle user clicked on the image
    if ((target).tagName.toLowerCase() === 'img') {
      let parentNode = target.parentNode;

      if (parentNode && (parentNode as HTMLAnchorElement).tagName.toLowerCase() === 'a') {
        return;
      }

      let src = target.getAttribute('src');

      if (!src) {
        return;
      }
      window.open(src, '_blank');
    }
  }

  let domain = getCloudFrontDomain('clario');


  return (
    <div className={`zchat__message-container zchat__message-container--${message.type} ${message.type === 'outgoing' && message.user ? '--agent' : ''}`}
         onClick={handleMessageClick}>
      <div className="zchat__message-content">
        <div className={`zchat__message-wrapper`}>
          <p className="zchat__message">
            <MessageText>{messageText}</MessageText>
          </p>
        </div>
        <div className="zchat__time-container">
          <span className="zchat__time">{TimeConverter.getAMPM(message.timestamp)}</span>
        </div>
      </div>

      <div className="zchat__message-avatar">
        <img src={`${domain}/images/aftersale.png`} className={'zchat__message-avatar_icon'} alt={'avatar'}/>
      </div>
    </div>
  );
}

export default Message;
