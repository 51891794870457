import React, {FunctionComponent} from 'react';

const Disconnected: FunctionComponent = () => {
  return (
    <div className='zchat__status'>
      <span className='zchat__status-icon zchat__status-icon--disconnected'/>
      <p className='zchat__status-text'>Write here to start your chat</p>
    </div>
  );
}

export default Disconnected;
