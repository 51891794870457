import {
  CLOSE_CHAT,
  CloseChatAction,
  OPEN_CHAT,
  OpenChatAction,
  SET_CHAT_CONFIG,
  SET_CONNECTION_STATUS,
  SET_TOOLTIP_STATE,
  SetChatConfigAction,
  SetConnectionStatusAction,
  SetTooltipStateAction,
  TOGGLE_CHAT,
  ToggleChatAction,
  TooltipState,
} from './types'
import {StorageState} from '../../foundations/services/storageStateManager';
import {ConnectionStatus} from '../../constants';
import {ChatConfig} from '../../interfaces/zchat';

export function openChat(): OpenChatAction {
  StorageState.setOpenChat();

  return {
    type: OPEN_CHAT,
    payload: {}
  }
}

export function toggleChat(): ToggleChatAction {
  StorageState.toggleIsOpenState();

  return {
    type: TOGGLE_CHAT,
    payload: {}
  }
}

export function setConnectionStatus(status: ConnectionStatus): SetConnectionStatusAction {
  return {
    type: SET_CONNECTION_STATUS,
    payload: {status: status}
  }
}

export function setChatConfig(config: ChatConfig): SetChatConfigAction {
  return {
    type: SET_CHAT_CONFIG,
    payload: config
  }
}

export function closeChat(): CloseChatAction {
  StorageState.setCloseChat();

  return {
    type: CLOSE_CHAT,
    payload: {}
  }
}

export function setTooltipState(state: TooltipState): SetTooltipStateAction {
  return {
    type: SET_TOOLTIP_STATE,
    payload: state
  }
}
