import React, {FunctionComponent} from 'react';
import {SuggestionsBlogConfig} from './suggestionsBlogConfig';
import SuggestionsBlogList from './suggestionsBlogList';
import {ChatConfig} from "../../interfaces/zchat";

type props = {
    chatConfig: ChatConfig | null,
}

const SuggestionsBlog: FunctionComponent<props> = ({chatConfig}) => {
  return (
    <div className={`zchat__suggestions --blog`}>
      <SuggestionsBlogList chatConfig={chatConfig} suggestions={SuggestionsBlogConfig}/>
    </div>
  )
}

export default SuggestionsBlog;
