import React, {FunctionComponent, useEffect, useState} from 'react';
import {Messages} from '../../store/messages/types';
import Tooltip from '../tooltip';
import {ChatConfig} from '../../interfaces/zchat';
import {StartChattingTooltipCopy, TooltipTypes} from '../../constants';
import {TooltipState} from '../../store/chat/types';
import {StorageState} from '../../foundations/services/storageStateManager';

type Props = {
  toggleChat: () => void;
  tooltipState: TooltipState,
  isOpen: boolean,
  messages: Messages | null,
  chatConfig: ChatConfig | null,
  setTooltipState: (state: TooltipState) => void,
}

const ChatBubbleBlog: FunctionComponent<Props> = ({toggleChat, tooltipState, isOpen, messages, chatConfig, setTooltipState}) => {
  const [unreadMessages, setUnreadMessage] = useState<Messages>([]);
  const [timeoutCallback, setTimeoutCallback] = useState<any>(null);
  const [tooltipTimeout, setTooltipTimeout] = useState(0);
  const [isNewPlateOpened, setNewPlateOpened] = useState(false);
  const [isPlateTextOpened, setPlateTextOpened] = useState(true);
  const [plateText, setPlateText] = useState('Need help? Expert is here');

  StorageState.setPlateText(plateText);

  useEffect(() => {
    processedMessagesByReadStatus();
  }, [messages]);

  useEffect(() => {
    processTooltipStateFromConfig();
  }, [chatConfig]);

  useEffect(() => {
    if (tooltipState.isOpen) {
      updateTooltipState(true, unreadMessages[unreadMessages.length - 1].body);
    }
  }, [unreadMessages]);

  useEffect(() => {
    if (!tooltipTimeout) {
      return;
    }

    setTimeoutCallback(setTimeout(() => {
      updateTooltipState(true, StartChattingTooltipCopy, TooltipTypes.startChatting);
    }, tooltipTimeout * 1000));
    return () => clearTimeout(timeoutCallback);
  }, [tooltipTimeout]);

  useEffect(() => {
    if (isOpen) {
      if (setTimeoutCallback != null) {
        clearTimeout(timeoutCallback);
      }
      updateTooltipState(false);
    }
  }, [isOpen]);

  const processedMessagesByReadStatus = () => {
    if (!messages) {
      return;
    }

    let unreadStatusMessages = messages.filter(message => !message.read);
    setUnreadMessage(unreadStatusMessages);
  }

  const updateTooltipState = (state: boolean, text = '', type = TooltipTypes.unreadMessage) => {
    setTooltipState({isOpen: state, text, type});
  }

  const processTooltipStateFromConfig = () => {
    if (!chatConfig?.startChattingTooltip) {
      return;
    }

    const {show, timeoutToShow, showNextTimeAfterClose} = chatConfig.startChattingTooltip;

    if (!show || !timeoutToShow || isOpen || (!showNextTimeAfterClose && StorageState.closeStartChattingTooltip)) {
      return;
    }

    setTooltipTimeout(timeoutToShow);
  }

  const handleHover = () => {
    if (unreadMessages.length) {
      updateTooltipState(true, unreadMessages[unreadMessages.length - 1].body, TooltipTypes.unreadMessage);
    }
  }

  const closeTextPlate = function (e: any) {
    e.stopPropagation();
    setPlateTextOpened(false);
  }

  const toggleChatWithPlate = function () {
    toggleChat();
    setPlateTextOpened(false);
    StorageState.setOpenChatWithBubble();
  }

  setTimeout(() => {
    setNewPlateOpened(true);
  }, 1000);

  return (
      <>
        <div
            className={`zchat--blog__bubble-container --blog ${isPlateTextOpened && isNewPlateOpened ? '--text-opened' : ''}`}
            onClick={toggleChatWithPlate}
            style={isNewPlateOpened ? {opacity: '1'} : {opacity: '0'}}
        >
          <div
              className={`zchat--blog__bubble zchat--blog__bubble--${chatConfig?.theme} ${isOpen ? 'zchat--blog__bubble--open' : 'zchat--blog__bubble--close'}`}
              onMouseEnter={handleHover}
          >
            {isOpen ?
                <i className='zchat--blog__bubble-icon zchat--blog__bubble-icon--close'/>
                : (
                    <>
                      <i className='zchat--blog__bubble-icon zchat--blog__bubble-icon--messages'/>
                      {unreadMessages.length ? (
                          <>
                            <div className='zchat--blog__unread-message-icon'>{unreadMessages.length}</div>
                          </>
                      ) : null}
                    </>)}
            <div className="zchat--blog__bubble-icon--dots">
              <span className="zchat--blog__bubble-icon--dot green"> </span>
              <span className="zchat--blog__bubble-icon--dot white"> </span>
              <span className="zchat--blog__bubble-icon--dot purple"> </span>
            </div>
            {unreadMessages.length ? (
                <>
                  <div className='zchat--blog__unread-message-icon'>{unreadMessages.length}</div>
                </>
            ) : null}
          </div>

          <div className={`zchat--blog__bubble--text`}>
            <i className='zchat--blog__bubble--text-close' onClick={closeTextPlate}/>
            {plateText}
          </div>

        </div>
        {tooltipState.isOpen && <Tooltip text={tooltipState.text || ''}/>}
      </>
  );
};

export default ChatBubbleBlog;
