import {ThemeNames} from '../themes';
import {Theme} from '../interfaces/themes';
import {ProductNames} from '../constants';

export const mackeeperConfig = {
  chatConfig: {
    theme: (ThemeNames.mk as Theme),
    productName: ProductNames.mackeeper,
    chatExternalEvents: {
      chat_started: 'mkSite_chat_started',
      chat_connected: 'zchat_user_connected',
      chat_timeout: 'mkSite_polling_broken',
      chat_shown: 'mkSite_user_showed_chat',
      chat_hidden: 'mkSite_user_hidden_chat',
      click_link: 'mkSite_link_clicked',
      chat_closed: 'mkSite_chat_closed',
    }
  },
  roomConfig: {
    type: 'PreRenewalEmail',
  }
};
