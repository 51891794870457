import {ChatConfig, Config, RoomConfig} from '../interfaces/zchat';
import {AgentInfo, AuthorizeResponse, CreteRoomResponse, GetLatestRoomResponse, GetRefreshTokenResponse, MessageType, RoomInfo, SetAgentRatingData} from '../interfaces/api';
import {MessageTypes} from '../constants';
import {getRequestHeaders} from '../foundations/utils/authHeader';
import handleResponse from '../foundations/utils/handleResponse';
import {API_CRM_ROOM_CREATE_CLARIO, HeadersContentType} from '../constants/api';
import Url from '../foundations/services/url';
import delay from '../foundations/utils/calcExpDelay';
import {MessageInterface, Messages} from '../store/messages/types';
import {StorageState} from '../foundations/services/storageStateManager';
import JwtParser from '../foundations/services/jwtParser';
import {ErrorType} from '../foundations/services/sentry/types';
import {SentryBrowser} from '../foundations/services/sentry';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {EventEmitter} from 'events';
import Events from '../constants/events';
import {generateUUID} from '../foundations/utils/generateUUID';
import {getSanitizedMessage} from '../foundations/utils/getSanitizedMessage';
import * as v8 from "v8";

export interface ClientInterface {
  isConnected: boolean;
  roomCreated: boolean;

  getRoomHistory(): Promise<Messages>;

  isAuthorized(): boolean;

  authorize(retryCount?: number, lastError?: string): Promise<void>;

  createRoom(): Promise<{roomId: string, clientId: string}>;

  sendMessage(message: string, messageType?: MessageType): Promise<Messages | void>;

  setMessageCallback(messageCallback: (messages: MessageInterface) => void): void;

  getAgentProfileInfo(): Promise<AgentInfo>;

  closeChat(): void;

  receiveMessage(): void;

  eraseAuthorization(): void;

  closeSession(): void;
}

export default class CommonClient implements ClientInterface {
  roomConfig: RoomConfig;
  chatConfig: ChatConfig;
  _isConnected: boolean;
  sendMessageUrl: string;
  accessToken: string;
  messages: Messages | null;
  getHistoryUrl: string;
  deviceId: string;
  createRoomUrl: string;
  getLatestRoomUrl: string;
  roomId: string;
  isRestored: boolean;
  isOpened: boolean;
  clientId: string;
  getAgentProfileUrl: string;
  setAgentRatingUrl: string;
  agentInfo: AgentInfo | null;
  receiveMessagesUrl: string;
  messageCallback: (messages: MessageInterface) => void;
  abortController: AbortController;
  eventEmitter: EventEmitter;
  _roomCreated: boolean;

  constructor(config: Config, eventEmitter: EventEmitter) {
    this.eventEmitter = eventEmitter;
    this.roomConfig = config.roomConfig;
    this.chatConfig = config.chatConfig;
    this.sendMessageUrl = '';
    // this.accessToken = StorageState.authData?.access_token ? StorageState.authData?.access_token : this.roomConfig?.access_token ? this.roomConfig.access_token : '' || '';
    this.accessToken = this.getAccessToken();
    this.messages = null;
    this.getHistoryUrl = StorageState.authData?.history_api_url || '';
    this.deviceId = '';
    this.createRoomUrl = StorageState.authData?.create_room_api_url || API_CRM_ROOM_CREATE_CLARIO;
    this.getLatestRoomUrl = StorageState.authData?.latest_room_api_url || '';
    this.roomId = StorageState.roomId;
    this.isRestored = false;
    this.isOpened = false;
    this.clientId = StorageState.clientId;
    this.getAgentProfileUrl = '';
    this.setAgentRatingUrl = '';
    this.receiveMessagesUrl = '';
    this.agentInfo = null;
    this.messageCallback = (messages: MessageInterface) => {
      // will be overridden after setup
    };
    this._isConnected = false;
    this.abortController = new AbortController();
    this._roomCreated = false;
  }

  static async getDeviceId(): Promise<string> {
    return generateUUID();
  }

  public get roomCreated(): boolean {
    return this._roomCreated;
  }

  private getAccessToken(): any {
    if(!StorageState.authData?.access_token) {
      if (this.roomConfig.access_token?.length) {
        const tokenFromSoft = {
          access_token: this.roomConfig.access_token
        };
        StorageState.updateAuthData(tokenFromSoft);
        return this.roomConfig.access_token
      }
      else {
        return ''
      }
    } else {
      return StorageState.authData?.access_token
    }
  }

  public isAuthorized(): boolean {
    if (!this.accessToken.length) {
      return false;
    }

    let jwt = new JwtParser(this.accessToken);

    if (jwt.isExpired()) {
      this.eraseAuthorization();
      return false;
    }

    return true;
  }

  public eraseAuthorization() {
    StorageState.setAuthData(null);
    StorageState.setRoomId('');
    StorageState.setClientId('');
    StorageState.setCloseChat();
    this.accessToken = '';
    this.roomId = '';
    this.clientId = '';
    this.sendMessageUrl = '';
    this.getHistoryUrl = '';
    this.getLatestRoomUrl = '';
    this.agentInfo = null;
    this.isConnected = false;
    this._roomCreated = false;
    this.deviceId = '';
  }

  public authorize(): Promise<void> {
    return Promise.resolve();
  }

  public processingAuthorizeResponse(response: AuthorizeResponse) {
    this.accessToken = response.access_token;
    this.createRoomUrl = response.create_room_api_url;
    this.getHistoryUrl = response.history_api_url;
    this.getLatestRoomUrl = response.latest_room_api_url;

    StorageState.setAuthData(response);
    return Promise.resolve(response);
  }

  get isConnected(): boolean {
    return this._isConnected;
  }

  set isConnected(connectionStatus: boolean) {
    this._isConnected = connectionStatus;
    this.eventEmitter.emit(this._isConnected ? Events.onConnected : Events.onDisconnected);
  }

  public setAccessToken(token: string): void {
    this.accessToken = token;
  }

  // Method for making api call with backoff retry strategy
  public async retryableFetch(apiCall: () => Promise<any>, retryCount = 0, lastError: string = ''): Promise<any> {
    if (retryCount > this.chatConfig.connectRetryLimit) throw new Error(lastError);
    try {
      return await apiCall();
    } catch (error) {

      if (error.status === 403 || error.status === 401) {
        this.eventEmitter.emit(Events.onCriticalError);
        Sentry.captureMessage(`Zchat was shut down because of inappropriate init configuration ${{...this.chatConfig, ...this.roomConfig}}`, Severity.Critical);
        return Promise.reject(`Zchat was shut down because of inappropriate init configuration`);
      }

      await delay(retryCount, this.chatConfig);
      await this.retryableFetch(apiCall, retryCount + 1, error.message);
    }
  }


  public async createRoom(): Promise<{roomId: string, clientId: string}> {
    return await this.retryableFetch((this.createRoomApiCall).bind(this));
  }

  // No need to pass mode
  // If mode is passed, url will be received without the clientId value, but with a placeholder %%clientId%%
  public async createRoomApiCall(): Promise<{roomId: string, clientId: string}> {
    let requestBody = {
      roomId: this.roomId,
      source: '',
      lang: this.roomConfig.lang,
      chatType: this.roomConfig.type,
      affid: this.roomConfig.affid,
      version: '1.0.0'
    };

    let requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
      body: JSON.stringify(requestBody)
    };

    return fetch(this.createRoomUrl, requestOptions)
      .then(handleResponse)
      .then((resp: CreteRoomResponse) => {
        this._roomCreated = true;
        return this.processingRoomInformation(resp);
      })
      .catch(error => {
        this._roomCreated = false;

        return this.handleError(error, this.createRoomUrl, 'room', requestBody)
      });
  }

  private processingRoomInformation(roomInfo: RoomInfo) {
    this.isOpened = roomInfo?.IsOpened || this.isOpened;
    this.isRestored = roomInfo?.isRestored || this.isRestored;
    this.roomId = roomInfo.room;
    this.sendMessageUrl = roomInfo.post;
    this.receiveMessagesUrl = roomInfo.get;
    this.getHistoryUrl = roomInfo.history;
    this.setAgentRatingUrl = roomInfo.setRating;
    this.getAgentProfileUrl = roomInfo.publicProfile;
    this.clientId = roomInfo.clientId;

    StorageState.setRoomId(this.roomId);
    StorageState.setClientId(this.clientId);
    return Promise.resolve({roomId: this.roomId, clientId: this.clientId});
  }

  public async getRoomHistory(): Promise<Messages> {
    return await this.retryableFetch((this.getRoomHistoryApiCall).bind(this));
  }

  private getRoomHistoryApiCall() {
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
    };

    return fetch(this.getHistoryUrl, requestOptions)
      .then(handleResponse)
      .then((resp: Messages) => {
        this.messages = resp;
        return Promise.resolve(this.messages);
      })
      .catch(error => this.handleError(error, this.getHistoryUrl, 'room'));
  }

  public getLatestRoom(): Promise<GetLatestRoomResponse> {
    let requestOptions: RequestInit = {
      method: 'GET',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
    };

    return fetch(Url.updateQueryStringParams(this.getLatestRoomUrl, {chat_type: this.roomConfig.type || ''}), requestOptions)
      .then(handleResponse)
      .then((resp: GetLatestRoomResponse) => {
        this.processingRoomInformation(resp);
        return Promise.resolve(resp);
      })
      .catch(error => this.handleError(error, Url.updateQueryStringParams(this.getLatestRoomUrl, {chat_type: this.roomConfig.type || ''}), 'room'));
  }

  public async sendMessage(message: string, messageType?: MessageType): Promise<Messages | void> {
    if (!message.trim().length) {
      return;
    }

    this.eventEmitter.emit(Events.onMessageSend);
    const sanitizedMessage = getSanitizedMessage(message);
    return await this.retryableFetch(this.sendMessageApiCall.bind(this, sanitizedMessage, messageType));
  }

  public sendMessageApiCall(message: string, messageType?: MessageType): Promise<Messages> {
    // All comments messages should started with //
    if (messageType === MessageTypes.comment && message.substring(0, 2) !== '//') {
      message = `//${message}`;
    }


    let requestBody: Record<string, string> = {
      message: message,
      mestype: messageType || MessageTypes.incoming,
    };

    let urlEncodeRequestBody = Object.keys(requestBody).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestBody[key])).join('&')

    let requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(this.accessToken, HeadersContentType.urlencoded),
      body: urlEncodeRequestBody,
      credentials: 'include',
      signal: this.abortController.signal,
    };

    return fetch(this.sendMessageUrl, requestOptions)
      .then(handleResponse)
      .then((resp: Messages) => {
        this.messages = resp;
        return Promise.resolve(this.messages);
      })
      .catch(error => {
        return this.handleError(error, this.sendMessageUrl, 'message')
      });
  }

  public getAgentProfileInfo(): Promise<AgentInfo> {
    if (!this.getAgentProfileUrl.length) {
      return Promise.reject('Url for getAgentProfileApi is missing');
    }

    let requestOptions: RequestInit = {
      method: 'GET',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
    };

    return fetch(this.getAgentProfileUrl, requestOptions)
      .then(handleResponse)
      .then((resp: AgentInfo) => {
        this.agentInfo = resp;

        if (this.chatConfig.agentNameFromHistory && StorageState.agentName && StorageState.agentName.trim().length) {
          return Promise.resolve(resp);
        }

        if (this.agentInfo.public_name && this.agentInfo.public_name.trim().length) {
          StorageState.setAgentName(this.agentInfo.public_name);
        }
        return Promise.resolve(resp);
      })
      .catch(error => this.handleError(error, this.getAgentProfileUrl, 'agent'));
  }

  public setAgentProfileRating(setAgentRatingData: SetAgentRatingData): Promise<void> {
    if (!this.setAgentRatingUrl.length) {
      return Promise.reject('Url for setAgentRatingUrl is missing');
    }

    if (!setAgentRatingData.rating) {
      return Promise.reject('No rating value');
    }

    let requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(this.accessToken, HeadersContentType.urlencoded),
      credentials: 'include',
      body: JSON.stringify(setAgentRatingData)
    };

    return fetch(this.setAgentRatingUrl, requestOptions)
      .then(handleResponse)
      .catch((error) => this.handleError(error, this.setAgentRatingUrl, 'agent', setAgentRatingData))
  }

  public setMessageCallback(messageCallback: (messages: MessageInterface) => void): void {
    this.messageCallback = messageCallback;
  }

  public receiveMessage(): void {
    this.subscribe();
  }

  private async getRefreshToken(): Promise<GetRefreshTokenResponse> {
    return await this.retryableFetch((this.getRefreshTokenApiCall).bind(this));
  }

  public handleReceiveRefreshTokenResponse(resp: GetRefreshTokenResponse): void {
    if (resp.access_token.length) {
      this.accessToken = resp.access_token;
      StorageState.updateAuthData(resp);
    }
  }

  public async getRefreshTokenApiCall(): Promise<void> {
    return Promise.resolve();
  }

  public closeChat(): void {
    this.abortController.abort();
    this.eraseAuthorization();
    this.abortController = new AbortController();
  }

  public closeSession(): void {
    this.eventEmitter.emit(Events.onSessionClose);
    this.closeChat();
  }

  private async refreshAccessToken() {
    this.abortController.abort();

    try {
      let refreshToken = await this.getRefreshToken();
      this.handleReceiveRefreshTokenResponse(refreshToken);
      this.isConnected = true;
      this.abortController = new AbortController();
      this.receiveMessage();
    } catch (error) {
      this.eraseAuthorization();
    }
  }

  async subscribe(retryCount = 0): Promise<void> {
    let requestOptions: RequestInit = {
      method: 'GET',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
      signal: this.abortController.signal,
    };
    let response = null;
    // &polling-disconnect-comment - needed to send chat events to agent that user has problem with connection
    // If requests with this parameter will be ended - the crm after 20 seconds will send according event to agent
    try {
      response = await fetch(`${this.receiveMessagesUrl}&polling-disconnect-comment=${this.chatConfig.chatExternalEvents?.chat_timeout}`, requestOptions);
    } catch (error) {
      Sentry.captureMessage(`Could not subscribe  -  ${error.message}`, Severity.Error);
    }

    // Status 444 is a connection timeout error,
    // may happen when the connection was pending for too long,
    // and the remote server or a proxy closed it
    // Or if agent close chat by close icon
    if (response?.status == 444) {
      // Handle close session without reinitialize  -> reInitializeOnCloseSession = false
      if (!this.chatConfig.reInitializeOnCloseSession) {
        this.closeSession();
        return;
      }

      this.eraseAuthorization();
      this.isConnected = false;
      this.eventEmitter.emit(Events.onBeforeClose);
      return;
    }

    if (response?.status != 200) {
      // An error - let's handle it
      // Reconnect by exponential backoff strategy
      if (response?.status == 401) {
        this.eraseAuthorization();
        return;
      }

      // Handle specific case with sf_room_assign
      // After merge - anonymous customer disconnect - without the ability to reopen the connection
      // if it is this case - we try to get a refresh token - to resume the connection
      if (response?.status == 403) {
        await this.refreshAccessToken();
        return;
      }

      this.isConnected = false;
      await delay(retryCount, this.chatConfig);
      await this.subscribe(retryCount + 1);
    } else {
      this.isConnected = true;
      this.messages = await handleResponse(response);
      this.messages?.forEach((message) => {
        this.messageCallback(message);
      })
      // Call subscribe() again to get the next message
      await this.subscribe();
    }
  }

  public async handleError(error: ErrorType, link: string, categoryName: string, requestParams?: {[key: string]: any}): Promise<any> {
    const errorLevel = SentryBrowser.determineErrorLevelForSentry(error);

    Sentry.configureScope((scope) => {
      scope.setLevel(errorLevel);
    })

    Sentry.addBreadcrumb({
      category: categoryName,
      message: 'Endpoint ' + link,
      data: {
        error: error,
        ...requestParams
      },
      level: errorLevel
    });

    Sentry.captureMessage(`Api call for ${link} failed with ${error.message}`, errorLevel);

    return Promise.reject({...error, status: error.status});
  }
}
