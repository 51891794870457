import React, {FunctionComponent, useState} from 'react';
import {useZchatContext} from '../zchatUI';
import {StorageState} from '../../foundations/services/storageStateManager';
import {MessageTypes} from '../../constants';
import {ChatConfig} from "../../interfaces/zchat";
import {SuggestionsBlogProductConfigType} from "./suggestionsSiteConfig";

type Props = {
    chatConfig?: ChatConfig | null,
    suggestions: Array<SuggestionsBlogProductConfigType>,
}

const SuggestionsSiteList: FunctionComponent<Props> = ({suggestions, chatConfig}) => {
    const {chatClient, messageMiddleware, messageQueueService} = useZchatContext();
    const isSuggestionsOpen = StorageState.isSuggestionsOpen;
    const roomId = StorageState.roomId;

    const [isOpened, setOpen] = useState(true);

    if (!chatClient) {
        return null;
    }

    const handleSuggestionsClose = () => {
        setOpen(false);
        StorageState.setOpenSuggestions();
    }

    const handleSendSuggestion = (event: any, message: string, customEvent?: string) => {
        messageMiddleware?.sendMessage(message);
        customEvent && messageQueueService?.sendMessage({message: customEvent, messageType: MessageTypes.comment});

        if (StorageState.plateText === 'Need help? Expert is here') {
            messageQueueService?.sendMessage({
                message: '//need_help_expert_is_here',
                messageType: MessageTypes.comment
            });
        }

        handleSuggestionsClose();
    }

    if (chatConfig?.productName === 'web2app' && chatConfig?.theme === 'wta') {
        return (
            <>
                {!isSuggestionsOpen && !roomId && isOpened &&
                    <div className='zchat__suggestions-blog-wrapper --blog'>
                        <div className='zchat__suggestions-blog --narrow'>
                            {suggestions && suggestions.length &&
                                suggestions.map((suggestion) => {
                                    return (
                                        <div
                                            className={`zchat__suggestions-blog-item js-suggestions-item --narrow ${suggestion.id}`}
                                            key={suggestion.id}
                                            onClick={(event) => handleSendSuggestion(event, suggestion.text, suggestion.event)}
                                        >
                                            {suggestion.text}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {!isSuggestionsOpen && !roomId && isOpened &&
                <div className='zchat__suggestions-blog-wrapper --blog'>
                    <div className="zchat__suggestions-blog-header">
                        Please specify your request
                    </div>
                    <div className={"zchat__suggestions-blog-title"}>Choose your answer</div>
                    <div className='zchat__suggestions-blog --wide'>
                        {suggestions && suggestions.length &&
                            suggestions.map((suggestion) => {
                                return (
                                    <div
                                        className={`zchat__suggestions-blog-item js-suggestions-item --wide ${suggestion.id}`}
                                        key={suggestion.id}
                                        onClick={(event) => handleSendSuggestion(event, suggestion.text, suggestion.event)}
                                    >
                                        {suggestion.text}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default SuggestionsSiteList;
