import React, {FunctionComponent} from 'react';

const DisconnectedWeb2app: FunctionComponent = () => {
  return (
    <div className='zchat__status'>
      <span className='zchat__status-icon zchat__status-icon--disconnected'/>
      <p className='zchat__status-text'>Connecting to <b>Andrew</b></p>
    </div>
  );
}

export default DisconnectedWeb2app;
