import React, {FunctionComponent} from 'react';
import AgentAvatar from '../../agentAvatar/agentAvatar';

const SessionClose: FunctionComponent = () => {
  return (
    <div className='zchat__status'>
      <AgentAvatar/>
      <p className='zchat__status-text zchat__status-text--dark'>Your chat is over </p>
    </div>
  );
}

export default React.memo(SessionClose);
