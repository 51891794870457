import {Config} from '../interfaces/zchat';
import {API_CRM_AUTHORIZE_GOI, API_CRM_GET_REFRESH_TOKEN_GOI} from '../constants/api';
import handleResponse from '../foundations/utils/handleResponse';
import CommonClient from './commonClient';
import {AuthorizeResponse} from '../interfaces/api';
import {getRequestHeaders} from '../foundations/utils/authHeader';
import {EventEmitter} from 'events';

declare var API_KEY_GOI: string;

export default class GOIClient extends CommonClient {
  private static instance: GOIClient;

  constructor(config: Config, eventEmitter: EventEmitter) {
    super(config, eventEmitter);
  }

  // Authorize method with exponential backoff retry
  public async authorize(): Promise<void> {
    return await this.retryableFetch((this.authorizeApiCall).bind(this));
  }

  private async authorizeApiCall(): Promise<AuthorizeResponse> {
    const requestBody = {
      mac: this.roomConfig.mac || '',
      sid: this.roomConfig.sid || await GOIClient.getDeviceId(),
      api_key: API_KEY_GOI,
      email: this.roomConfig.email,
    };

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(),
      credentials: 'include',
      body: JSON.stringify(requestBody)
    };

    return fetch(API_CRM_AUTHORIZE_GOI, requestOptions)
      .then(handleResponse)
      .then(this.processingAuthorizeResponse.bind(this))
      .catch((error) => this.handleError(error, API_CRM_AUTHORIZE_GOI, 'auth', {...this.chatConfig, ...this.roomConfig}));
  }

  public async getRefreshTokenApiCall(): Promise<void> {
    const getRefreshTokenApiLink = `${API_CRM_GET_REFRESH_TOKEN_GOI}/${this.roomId}/`;
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: getRequestHeaders(this.accessToken),
      credentials: 'include',
    };

    return fetch(getRefreshTokenApiLink, requestOptions)
      .then(handleResponse)
      .then(resp => Promise.resolve(resp))
      .catch(error => this.handleError(error, getRefreshTokenApiLink, 'refresh-token'));
  }

  public static getInstance(config: Config, eventEmitter: EventEmitter): GOIClient {
    if (!GOIClient.instance) {
      GOIClient.instance = new GOIClient(config, eventEmitter);
    }

    return GOIClient.instance;
  }
}
