export default class Url {
  static updateQueryStringParams(link: string, params: {[key: string]: string}): string {
    let url = new URL(link);
    let searchParams = url.searchParams;

    if (!Object.keys(params).length) {
      return url.toString();
    }

    for (let prop in params) {
      if (Object.prototype.hasOwnProperty.call(params, prop)) {
        searchParams.set(prop, params[prop]);
      }
    }

    return url.toString();
  }

  static getArrayOfParams(link: string): Array<{[key: string]: any}> {
    const url = new URL(link);
    const queryParams = new URLSearchParams(url.search);
    return [...queryParams.entries()];
  }

  static getAllQueryParams(link: string): URLSearchParams {
    const url = new URL(link);
    return new URLSearchParams(url.search);
  }

  static deleteQueryParams(link: string): string {
    const url = new URL(link);

    return url.origin;
  }

  static getQueryParamByName(link: string, name: string): string | null {
    const url = new URL(link);
    const queryParams = new URLSearchParams(url.search);
    return queryParams.get(name);
  }

  static objToQueryString(params: {[key: string]: string}): string {
    const keyValuePairs = [];
    for (const key in params) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    }
    return keyValuePairs.join('&');
  }
}
