import {connect} from 'react-redux';
import {closeChat, setChatConfig, setConnectionStatus, toggleChat} from '../../store/chat/actions';
import {RootState} from '../../store'
import {addMessage, setHistory, setReadMessagesState} from '../../store/messages/actions';
import {Messages, MessageInterface} from '../../store/messages/types';
import ZchatUI from './zchatUI';
import {ConnectionStatus} from '../../constants';
import {AgentProfile} from '../../store/agent/types';
import {setAgentProfileInfo} from '../../store/agent/actions';
import {ChatConfig} from '../../interfaces/zchat';

type StateType = {
  isOpen: boolean,
  messages: Messages | null;
  agentProfile: AgentProfile | null;
  connectionStatus: ConnectionStatus;
}

const mapStateToProps = (state: RootState): StateType => ({
  isOpen: state.chat.isOpen,
  messages: state.messages.messages,
  agentProfile: state.agent.agentProfile,
  connectionStatus: state.chat.connectionStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleChat: (): void => {
    dispatch(toggleChat());
  },
  setHistory: (messages: Messages): void => {
    dispatch(setHistory(messages));
  },
  addMessage: (message: MessageInterface, messageType: string): void => {
    dispatch(addMessage(message, messageType));
  },
  setReadMessagesState: (): void => {
    dispatch(setReadMessagesState());
  },
  setConnectionStatus: (status: ConnectionStatus): void => {
    dispatch(setConnectionStatus(status));
  },
  setAgentProfileInfo: (agentProfile: AgentProfile | null): void => {
    dispatch(setAgentProfileInfo(agentProfile));
  },
  setChatConfig: (config: ChatConfig): void => {
    dispatch(setChatConfig(config));
  },
  closeChat: (): void => {
    dispatch(closeChat());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ZchatUI);
