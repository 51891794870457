import {useSelector} from 'react-redux';
import {ChatConfig} from '../../interfaces/zchat';
import {RootState} from '../../store';
import {StorageState} from '../services/storageStateManager';
import {defaultConfig} from '../../configs';

const useDetermineAgentName = (): string => {
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);
  let chatConfig: ChatConfig | null = useSelector((state: RootState) => state.chat.chatConfig);

  if (!chatConfig) {
    return '';
  }
  let {defaultAgentName = '', forcedAgentName, agentNameFromHistory} = chatConfig;

  if (forcedAgentName && forcedAgentName.trim().length) {
    return forcedAgentName;
  }

  if (agentNameFromHistory && StorageState.agentName.trim().length) {
    return StorageState.agentName;
  }

  if (agentProfile?.public_name && agentProfile?.public_name.trim().length) {
    return agentProfile?.public_name;
  }

  return defaultAgentName.trim().length ? defaultAgentName : defaultConfig.chatConfig.defaultAgentName;
}

export default useDetermineAgentName;
