import React, {FunctionComponent, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import DefaultAvatar from './defaultAvatar';

type Props = {
  className?: string;
}

const AgentAvatar: FunctionComponent<Props> = ({className = ''}) => {
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);
  const chatConfig = useSelector((state: RootState) => state.chat.chatConfig);
  const [errorLoadingAgentProfile, setErrorLoadingAgentProfile] = useState(false);

  const handleErrorLoadingAgentProfile = () => {
    setErrorLoadingAgentProfile(true);
  }

  if (errorLoadingAgentProfile) {
    return <DefaultAvatar className={className}/>;
  }

  if (!chatConfig?.showAgentPhoto) {
    return <DefaultAvatar className={className}/>;
  }

  if (!agentProfile || !agentProfile.photo_url || !agentProfile.photo_url.trim().length) {
    return <DefaultAvatar className={className}/>;
  }


  return (
    <img className={`zchat__agent-avatar ${className}`} src={agentProfile?.photo_url} alt='Agent profile photo' onError={handleErrorLoadingAgentProfile}/>
  )

}

export default React.memo(AgentAvatar);
