import {ThemeNames} from '../themes';
import {Theme} from '../interfaces/themes';
import {ChatRoomTypes, ProductNames} from '../constants';

export const goinvisibleConfig = {
  chatConfig: {
    theme: (ThemeNames.goi as Theme),
    productName: ProductNames.goinvisible,
    chatExternalEvents: {
      chat_started: '//ff_mk_goi_landing_chat_started',
      chat_connected: 'zchat_user_connected',
      chat_timeout: 'mk_goi_landing_polling_broken',
      chat_shown: 'mk_goi_landing_user_showed_chat',
      chat_hidden: 'mk_goi_landing_user_hidden_chat',
      click_link: 'mk_goi_landing_link_clicked',
      chat_closed: 'mk_goi_landing_chat_closed',
    }
  },
  roomConfig: {
    type: ChatRoomTypes.letsgotoRA,
  }
};
