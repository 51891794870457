import {HeadersContentType} from '../../constants/api';
import objectOmitNull from './objectOmitNull';

export function getRequestHeaders(token?: string, contentType = HeadersContentType.json): Headers {
  let headers = {
    'Content-Type': contentType,
    'Authorization': token ? `Bearer ${token}` : null,
  };

  return new Headers(objectOmitNull(headers));
}
