import {LocalStorage} from '../storage';
import {AuthorizeData, AuthorizeResponse} from '../../../interfaces/api';

export interface StorageStateManagerInterface {
  readonly roomId: string;
  readonly isOpenState: boolean;
}

export type DefaultStorageState = {
  isOpen: boolean,
  isSuggestionsOpen: boolean,
  isAutomessagesChosen: boolean,
  isStartMessageChosen: boolean,
  isSentCustomMessage: boolean,
  plateText: string,
  roomId: string,
  clientId: string,
  agentName: string,
  authData: null | AuthorizeResponse,
  closeStartChattingTooltip: boolean,
  roomType: string,
}

const ZchatLocalStorageKey = 'zchat_state';

const DefaultState = {
  isOpen: false,
  isOpenWithBubble: false,
  isSuggestionsOpen: false,
  isAutomessagesChosen: false,
  isStartMessageChosen: false,
  isSentCustomMessage: false,
  plateText: '',
  roomId: '',
  clientId: '',
  authData: null,
  agentName: '',
  closeStartChattingTooltip: false,
  roomType: '',
}

export class StorageStateManager implements StorageStateManagerInterface {
  _defaultState: DefaultStorageState;

  constructor() {
    this._defaultState = {...DefaultState};
  }

  private getStorageState() {
    let storageState = JSON.parse((LocalStorage.getItem(ZchatLocalStorageKey) as string));

    if (!storageState) {
      storageState = {...this._defaultState};
      LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify(storageState));
    }

    return storageState;
  }

  public get roomId(): string {
    let storageState = this.getStorageState();

    return storageState.roomId;
  }
  public get isSuggestionsOpen(): boolean {
    let storageState = this.getStorageState();

    return storageState.isSuggestionsOpen;
  }

  public get isAutomessagesChosen(): boolean {
    let storageState = this.getStorageState();

    return storageState.isAutomessagesChosen;
  }

  public get isStartMessageChosen(): boolean {
    let storageState = this.getStorageState();

    return storageState.isStartMessageChosen;
  }

  public get isSentCustomMessage(): boolean {
    let storageState = this.getStorageState();

    return storageState.isSentCustomMessage;
  }

  public get plateText(): string {
    let storageState = this.getStorageState();

    return storageState.plateText;
  }

  public get roomType(): string {
    let storageState = this.getStorageState();

    return storageState.roomType;
  }

  public get clientId(): string {
    let storageState = this.getStorageState();

    return storageState.clientId;
  }

  public get isOpenState(): boolean {
    let storageState = this.getStorageState();

    return storageState.isOpen;
  }

  public get isAgentConnectedState(): boolean {
    let storageState = this.getStorageState();

    return storageState.isAgentConnected;
  }

  public get isOpenWithBubbleState(): boolean {
    let storageState = this.getStorageState();

    return storageState.isOpenWithBubble;
  }

  public get closeStartChattingTooltip(): boolean {
    let storageState = this.getStorageState();

    return storageState.closeStartChattingTooltip;
  }

  public get authData(): null | AuthorizeResponse {
    let storageState = this.getStorageState();

    return storageState.authData;
  }

  public get agentName(): string {
    let storageState = this.getStorageState();

    return storageState.agentName;
  }

  public setRoomId(roomId: string): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, roomId: roomId}));
  }

  public setClientId(clientId: string): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, clientId: clientId}));
  }

  public setOpenChat(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isOpen: true}));
  }

  public setAgentConnected(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isAgentConnected: true}));
  }

  public setOpenChatWithBubble(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isOpenWithBubble: true}));
  }

  public setAutomessagesChosen(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isAutomessagesChosen: true}));
  }

  public setStartMessageChosen(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isStartMessageChosen: true}));
  }

  public setSentCustomMessage(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isSentCustomMessage: true}));
  }

  public setOpenSuggestions(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isSuggestionsOpen: true}));
  }

  public setPlateText(text: string): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, plateText: text}));
  }

  public setCloseChat(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isOpen: false}));
  }

  public toggleIsOpenState(): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, isOpen: !storageState.isOpen}));
  }

  public setAuthData(response: AuthorizeResponse | null): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, authData: response}));
  }

  public updateAuthData(authData: AuthorizeData | null): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, authData: {...storageState.authData, ...authData}}));
  }

  public setCloseStartChattingTooltip(value: boolean): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, closeStartChattingTooltip: value}));
  }

  public setAgentName(name: string): void {
    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, agentName: name}));
  }

  public setRoomType(roomType: string | any): void {
    if (typeof roomType !== 'string') {
      throw new Error(`StorageStateManager: roomType - ${roomType} - should be a string`);
    }

    if (!roomType.trim().length) {
      throw new Error(`StorageStateManager: roomType is empty`);
    }

    let storageState = this.getStorageState();

    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...storageState, roomType: roomType}));
  }

  public eraseAll(): void {
    LocalStorage.setItem(ZchatLocalStorageKey, JSON.stringify({...DefaultState}));
  }
}

export const StorageState = new StorageStateManager();
