import {ConnectionStatus, TooltipTypes} from '../../constants'
import {StorageState} from '../../foundations/services/storageStateManager'
import {ChatActionTypes, ChatState, CLOSE_CHAT, OPEN_CHAT, SET_CHAT_CONFIG, SET_CONNECTION_STATUS, SET_TOOLTIP_STATE, TOGGLE_CHAT} from './types'

const initialState: ChatState = {
  isOpen: StorageState.isOpenState,
  connectionStatus: ConnectionStatus.Disconnected,
  chatConfig: null,
  tooltip: {isOpen: false},
}

export function chatReducer(
  state = initialState,
  action: ChatActionTypes
): ChatState {
  switch (action.type) {
    case TOGGLE_CHAT:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case OPEN_CHAT:
      return {
        ...state,
        isOpen: true,
      }
    case SET_CONNECTION_STATUS:
      if (!navigator.onLine && action.payload.status !== ConnectionStatus.NoInternetConnection) {
        return {...state};
      }

      // handle config option reInitializeOnCloseSession
      // Used for mobile web view
      if (state.connectionStatus == ConnectionStatus.SessionClose && (action.payload.status == ConnectionStatus.Disconnected || action.payload.status == ConnectionStatus.Connected)) {
        return {...state}
      }

      if (action.payload.status === state.connectionStatus) {
        return {...state};
      }

      return {
        ...state,
        connectionStatus: action.payload.status,
      }
    case SET_CHAT_CONFIG:
      return {
        ...state,
        chatConfig: action.payload,
      }
    case SET_TOOLTIP_STATE:
      if (!action.payload.isOpen && state.tooltip.type == TooltipTypes.startChatting) {
        StorageState.setCloseStartChattingTooltip(true);
      }
      return {
        ...state,
        tooltip: action.payload.isOpen ? action.payload : {...action.payload, text: ''},
      }
    case CLOSE_CHAT:
      return {...initialState, isOpen: false, chatConfig: state.chatConfig}
    default:
      return state
  }
}
