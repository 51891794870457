import React, {FunctionComponent, Suspense, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ConnectionStatus} from '../../../constants';
import {RootState} from '../../../store';
import Connected from './connected';
import Connecting from './connecting';
import Disconnected from './disconnected';
import NoInternet from './noInternet';
import SessionClose from './sessionClose.';

const Statuses: FunctionComponent = () => {
  const connectionStatus = useSelector((state: RootState) => state.chat.connectionStatus);
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);

  const determineConnectionStatus = () => {
    switch (connectionStatus) {
      case ConnectionStatus.Disconnected:
        return <Disconnected/>;
      case ConnectionStatus.Connecting:
        return <Connecting/>;
      case ConnectionStatus.AgentConnected:
        return <Connected/>;
      case ConnectionStatus.NoInternetConnection:
        return <NoInternet/>;
      case ConnectionStatus.Connected:
        return agentProfile ? <Connected/> : <Connecting/>;
      case ConnectionStatus.SessionClose:
        return <SessionClose/>;
      default:
        return <Disconnected/>
    }
  }

  return (
    <Suspense fallback={null}>
      <>
        {determineConnectionStatus()}
      </>
    </Suspense>
  );
}

export default Statuses;

