import Events from '../../../constants/events';
import {EventEmitter} from 'events';
import ChatClient from '../../../chatClient';
import {ChatExternalEventsType} from '../../../interfaces/zchat';
import {MessageTypes} from '../../../constants';
import getBrowserName from '../../utils/getBrowserName';
import {MessageQueueInterface} from '../messageQueue';

// This service send events for agents - about chat state or user actions
export class ChatExternalEventsService {
  private static instance: ChatExternalEventsService;
  eventEmitter: EventEmitter;
  chatClient: ChatClient | null;
  messageQueueService: MessageQueueInterface | null;
  chatEventsConfig: ChatExternalEventsType;
  restoredChat: boolean;

  constructor(eventEmitter: EventEmitter, chatClient: ChatClient | null, messageQueueService: MessageQueueInterface | null, chatEvents: ChatExternalEventsType) {
    this.eventEmitter = eventEmitter;
    this.chatClient = chatClient;
    this.chatEventsConfig = chatEvents;
    this.messageQueueService = messageQueueService;
    this.subscribeToInternalChatEvents();
    this.restoredChat = false;
  }

  private subscribeToInternalChatEvents() {
    this.eventEmitter.once(Events.onAuthorized, this.handleInitialStart.bind(this));
    this.eventEmitter.once(Events.onConnected, this.handleInitialConnect.bind(this));
    this.eventEmitter.on(Events.onUserToggleChat, this.handleUserToggleChat.bind(this));
    this.eventEmitter.on(Events.onUserClickLink, this.handleUserClickLink.bind(this));
  }

  private unSubscribeFromInternalChatEvents() {
    this.eventEmitter.off(Events.onAuthorized, this.handleInitialStart.bind(this));
    this.eventEmitter.off(Events.onConnected, this.handleInitialConnect.bind(this));
    this.eventEmitter.off(Events.onUserToggleChat, this.handleUserToggleChat.bind(this));
    this.eventEmitter.off(Events.onUserClickLink, this.handleUserClickLink.bind(this));
  }

  private async sendEvent(event: string) {
    await this.messageQueueService?.sendMessage({message: event, messageType: (MessageTypes.comment as 'comment')});
  }

  public shutDown() {
    this.unSubscribeFromInternalChatEvents();
  }

  // Internal events handlers
  private handleInitialStart() {
    this.sendEvent(this.chatEventsConfig.chat_started);
  }

  private handleInitialConnect() {
    this.sendEvent(this.chatEventsConfig.chat_connected);
    this.sendEvent(getBrowserName());
  }

  private handleUserToggleChat({open = false}: {open: boolean}) {
    if (!this.chatClient || !this.chatClient.isConnected()) {
      return;
    }

    let eventName = open ? this.chatEventsConfig.chat_shown : this.chatEventsConfig.chat_hidden;
    this.sendEvent(eventName);
  }

  private handleUserClickLink(link: string) {
    this.sendEvent(`${this.chatEventsConfig.click_link} ${link}`);
  }

  public static getInstance(eventEmitter: EventEmitter, chatClient: ChatClient | null, messageQueueService: MessageQueueInterface | null, chatEvents: ChatExternalEventsType): ChatExternalEventsService {
    if (!ChatExternalEventsService.instance) {
      ChatExternalEventsService.instance = new ChatExternalEventsService(eventEmitter, chatClient, messageQueueService, chatEvents);
    }

    return ChatExternalEventsService.instance;
  }
}

export default ChatExternalEventsService;

