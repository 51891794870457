import React, {FunctionComponent, ReactElement, useEffect, useRef, useState} from 'react';
import {useZchatContext} from '../zchatUI';
import {checkMessageLength, sanitizeMessage} from '../../foundations/handlers';
import Emoji from '../emoji';
import {BrowserNames, ConnectionStatus, MessageTypes} from '../../constants';
import {ChatConfig} from '../../interfaces/zchat';
import {Messages} from '../../store/messages/types';
import getBrowserInfo, {BrowserInfoType} from '../../foundations/utils/getBrowserInfo';
import {StorageState} from "../../foundations/services/storageStateManager";

type Props = {
  chatConfig?: ChatConfig | null,
  connectionStatus?: ConnectionStatus,
  messages?: Messages | null;
  setLoadingStatus?: (status: boolean) => void;
  setSuggestionsClose?: () => void;
  isSuggestionsInput?: boolean;
}

const InputArea: FunctionComponent<Props> = ({chatConfig, messages, connectionStatus, setLoadingStatus, setSuggestionsClose, isSuggestionsInput}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(document.createElement('textarea'));
  const {messageMiddleware, messageQueueService} = useZchatContext();
  const [rows, setRows] = useState(1);
  const [minRows] = useState(1);
  const [maxRows] = useState(2);
  const [messagesText, setMessagesText] = useState('');
  const [browserInfo, setBrowserInfo] = useState<BrowserInfoType>();
  const isSentCustomMessage = StorageState.isSentCustomMessage;
  const isStartMessageChosen = StorageState.isStartMessageChosen;
  const [isPlateTextEventSent, setPlateTextEventSent] = useState(false);

  // Setup chainer handler - for validate sending messages
  useEffect(() => {
    messageMiddleware?.addHandler(sanitizeMessage);
    messageMiddleware?.addHandler(checkMessageLength);
    messageMiddleware?.setFinal(sendMessage);
    setBrowserInfo(getBrowserInfo());
  }, []);

  // Handle autoFocus option from init config
  useEffect(() => {
    if (chatConfig?.autoFocus) {
      textareaRef.current.focus();
    }
  }, [chatConfig]);

  // Handle resize textarea
  const handleChangeTextarea = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 25;

    const previousRows = ev.target.rows;
    ev.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(ev.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      ev.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      ev.target.rows = maxRows;
      ev.target.scrollTop = ev.target.scrollHeight;
    }

    setMessagesText(ev.target.value);
    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  // Send message on press enter
  const handleKeyDownEvents = (ev: React.KeyboardEvent) => {
    if (!chatConfig?.sendOnEnter) {
      if (ev.keyCode === 13 && ev.shiftKey) {

        handleSendMessages();
        ev.preventDefault();
      }
      return;
    }

    if (ev.keyCode === 13 && !ev.shiftKey) {
      handleSendMessages();
      ev.preventDefault();
    }
  }

  const handlePlateTextEvents = () => {
    const plateText =  StorageState.plateText;
    switch (plateText) {
      case 'Solution here. Just click.':
        messageQueueService?.sendMessage({message: '//solution_here_just click', messageType: MessageTypes.comment});
        break;
      case 'Hit me up!':
        messageQueueService?.sendMessage({message: '//hit_me_up!', messageType: MessageTypes.comment});
        break;
      case 'Need help? Expert is here':
        messageQueueService?.sendMessage({message: '//need_help_expert_is_here', messageType: MessageTypes.comment});
        break;
    }
  }

  // If message will be bad - input will be empty
  const handleSendMessages = () => {
    // Prevent send message with internet problem
    if (connectionStatus === ConnectionStatus.NoInternetConnection) {
      return;
    }

    if (!messages || !messages.length) {
      setLoadingStatus?.(true);
    }

    messageMiddleware?.sendMessage(messagesText);
    setSuggestionsClose?.();
    resetInput();
    !isSentCustomMessage && StorageState.setSentCustomMessage();
    !isStartMessageChosen && StorageState.setStartMessageChosen();

    if ((isSuggestionsInput || !chatConfig?.suggestions) && chatConfig?.productName === 'web2app') {
      messageQueueService?.sendMessage({message: window.location.href.split('?')[0], messageType: MessageTypes.comment});
      messageQueueService?.sendMessage({message: '//custom_request', messageType: MessageTypes.comment});
    }

    if (!isPlateTextEventSent) {
      handlePlateTextEvents();
      setPlateTextEventSent(true);
    }
  }

  const resetInput = () => {
    setMessagesText('');
    setRows(1);
  }

  const sendMessage = (text: string) => {
    messageQueueService?.sendMessage({message: text});
    resetInput();
  }

  const emojiInserted = (messageWithEmoji: string) => {
    setMessagesText(messageWithEmoji);
    textareaRef.current.focus();
  };

  const renderEmoji = (): ReactElement | null => {
    if (!browserInfo) {
      return null;
    }

    // Emoji mart doesn't support old version of safari
    if (browserInfo.name === BrowserNames.Safari && Number(browserInfo.version) <= 10) {
      return null;
    }

    return <Emoji value={messagesText} onSelection={emojiInserted} productName={chatConfig?.productName} browserInfo={browserInfo}/>;
  };

  const validation = function () {
    //https://clario.atlassian.net/browse/MKM-13393 | Implement an additional restriction for sending emojis in Clario chat for a specific page.
    const url = window.location.href;
    const emojiPattern = /^[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\s]+$/gu;

    if (url.indexOf('clario.co/digital-security-experts') !== -1 && !StorageState.isSentCustomMessage) {
      return connectionStatus === ConnectionStatus.NoInternetConnection || messagesText === '' || messagesText.trim() === '' || emojiPattern.test(messagesText);
    }
    //

    return connectionStatus === ConnectionStatus.NoInternetConnection || messagesText === '' || messagesText.trim() === '';
  }
  if (chatConfig?.automessages) {
    return (
        <div className={`zchat__input-area-wrapper zchat__data-protection-input-area-wrapper`}>
          <textarea
              ref={textareaRef}
              className='zchat__input zchat__data-protection-input'
              placeholder='Your any question goes here :)'
              rows={rows}
              value={messagesText}
              onKeyDown={handleKeyDownEvents}
              onChange={handleChangeTextarea}/>
          <button className='zchat__send-message zchat__data-protection-send-message' onClick={handleSendMessages} disabled={validation()}/>
        </div>
    );
  } else {
    return (
        <div className={`zchat__input-area-wrapper ${isSuggestionsInput ? '--suggestions' : ''}`}>
          {renderEmoji()}
          <textarea
              ref={textareaRef}
              className='zchat__input'
              placeholder={isSuggestionsInput ? 'My other question is here' : 'Tell us about your problem'}
              rows={rows}
              value={messagesText}
              onKeyDown={handleKeyDownEvents}
              onChange={handleChangeTextarea}/>
          <button className='zchat__send-message' onClick={handleSendMessages} disabled={validation()}/>
        </div>
    );
  }
}

export default InputArea;
