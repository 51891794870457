import {createStore} from 'redux';
import Middleware from './middleware';
import {combineReducers} from 'redux';
import {messagesReducer} from './messages/reducers'
import {chatReducer} from './chat/reducers'
import {agentReducer} from './agent/reducers'

const RootReducer = combineReducers({
  messages: messagesReducer,
  chat: chatReducer,
  agent: agentReducer,
})

export type RootState = ReturnType<typeof RootReducer>

const store = createStore(
  RootReducer,
  Middleware,
);

export default store;
