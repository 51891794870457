import {connect} from 'react-redux';
import {setTooltipState} from '../../store/chat/actions';
import ChatBubbleBlog from './chatBubbleBlog';
import {TooltipState} from '../../store/chat/types';
import {RootState} from '../../store';
import {Messages} from '../../store/messages/types';
import {ChatConfig} from '../../interfaces/zchat';

type StateType = {
  tooltipState: TooltipState,
  isOpen: boolean,
  messages: Messages | null,
  chatConfig: ChatConfig | null,
}

const mapStateToProps = (state: RootState): StateType => ({
  tooltipState: state.chat.tooltip,
  isOpen: state.chat.isOpen,
  messages: state.messages.messages,
  chatConfig: state.chat.chatConfig,
});

const mapDispatchToProps = (dispatch: any) => ({
  setTooltipState: (state: TooltipState): void => {
    dispatch(setTooltipState(state));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatBubbleBlog);
