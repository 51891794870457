import {MessageType} from '../../../store/messages/types';
import {EventEmitter} from 'events';
import ChatClient from '../../../chatClient';
import Events from '../../../constants/events';

type MessageInQueue = {
  message: string,
  messageType?: MessageType | string
}

export interface MessageQueueInterface {
  sendMessage(message: MessageInQueue): Promise<void>;

  shutDown(): void;
}

// Service handling messaged queue
// Fulfill array of messages - and send one by one according to order
// Prevent start multiple auth calls
export class MessageQueueService implements MessageQueueInterface {
  private static instance: MessageQueueService;
  eventEmitter: EventEmitter;
  chatClient: ChatClient | null;
  messagesQueue: Array<MessageInQueue>;
  sendingMessage: boolean;
  isConnected: boolean;
  isTryToConnect: boolean;

  constructor(eventEmitter: EventEmitter, chatClient: ChatClient | null) {
    this.eventEmitter = eventEmitter;
    this.chatClient = chatClient;
    this.sendingMessage = false;
    this.isConnected = chatClient?.isConnected() || false;
    this.messagesQueue = [];
    this.isTryToConnect = false;
    this.subscribeToChangeChatState();
  }

  private setConnectedState(state: boolean): void {
    this.isConnected = state;
  }

  public shutDown() {
    this.eventEmitter.off(Events.onConnected, this.handleChatConnect);
    this.eventEmitter.off(Events.onDisconnected, this.handleDisconnect);
  }

  private subscribeToChangeChatState(): void {
    this.eventEmitter.on(Events.onConnected, this.handleChatConnect.bind(this));
    this.eventEmitter.on(Events.onDisconnected, this.handleDisconnect.bind(this));
  }

  private async handleChatConnect() {
    this.setConnectedState(true);
    await this.checkQueueForUnSendMessages();
  }

  private handleDisconnect() {
    this.setConnectedState(false);
  }

  private addMessagesToQueue(message: MessageInQueue) {
    this.messagesQueue.push(message);
  }

  private async checkQueueForUnSendMessages() {
    if (this.messagesQueue.length > 0) {
      await this.sendMessageFromQueue();
    }
  }

  private async sendMessageFromQueue() {
    if (this.sendingMessage) {
      return;
    }

    this.sendingMessage = true;
    let message = this.messagesQueue.shift();

    if (!message) {
      return;
    }

    await this.chatClient?.sendMessage(message.message, message.messageType);
    this.sendingMessage = false;

    await this.checkQueueForUnSendMessages();
  }

  public async sendMessage(message: MessageInQueue): Promise<void> {
    this.addMessagesToQueue(message);


    if (this.isConnected) {
      await this.sendMessageFromQueue();
      return;
    }

    if (this.isTryToConnect) {
      return;
    }

    this.isTryToConnect = true;

    await this.chatClient?.authorize();
    this.isTryToConnect = false;
    await this.sendMessageFromQueue();
  }

  public static getInstance(eventEmitter: EventEmitter, chatClient: ChatClient | null): MessageQueueService {
    if (!MessageQueueService.instance) {
      MessageQueueService.instance = new MessageQueueService(eventEmitter, chatClient);
    }

    return MessageQueueService.instance;
  }
}
