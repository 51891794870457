import React, {FunctionComponent} from 'react';
import AgentAvatar from '../../../components/agentAvatar';
import useDetermineAgentName from '../../../foundations/hooks/useDetermineAgentName';
import {getCloudFrontDomain} from "../../../constants";


const Connected: FunctionComponent = () => {
  const agentName = useDetermineAgentName();
  let domain = getCloudFrontDomain('clario');

  return (
    <div className='zchat__status'>
        <div className="zchat__status-icon_wrapper">
            <img src={`${domain}/images/aftersale.png`} className={'zchat__status-icon'}/>
        </div>
        <p className='zchat__status-text zchat__status-text--dark'>Connected to Joe</p>
        <p className="zchat__status-subtext">digital security expert</p>
    </div>
  );
}

export default React.memo(Connected);
