import '../../../resources/css/emoji-mart.css';
import React, {useState, useCallback, useRef, FunctionComponent, useEffect} from 'react';
import {Picker, EmojiData} from 'emoji-mart';
import useClickOutside from '../../foundations/hooks';
import {BrowserNames, getCloudFrontDomain} from '../../constants';
import {ProductName} from '../../interfaces/zchat';
import {EmojiBlackList} from './emojiBlackList';
import {BrowserInfoType} from '../../foundations/utils/getBrowserInfo';
import {StorageState} from "../../foundations/services/storageStateManager";

interface EmojiProps {
  value: string;
  productName?: ProductName,
  browserInfo: BrowserInfoType,

  onSelection(contentWithEmoji: string): any;
}

const Emoji: FunctionComponent<EmojiProps> = ({value, onSelection, productName, browserInfo}) => {
  const [showPicker, setPickerState] = useState(false);
  const picker = useRef<HTMLDivElement>(null);

  const dismissPicker = useCallback(() => {
    setPickerState(false);
  }, [setPickerState]);

  useClickOutside([picker], dismissPicker);

  const togglePicker = () => {
    setPickerState(!showPicker);
  };

  const addEmoji = (emoji: EmojiData) => {
    if ('native' in emoji) {
      onSelection(`${value} ${emoji.native} `);
      dismissPicker();
    }
  };

  const filterEmoji = (emoji: EmojiData) => {
    // Handle problem for old safari version - unicode symbols reserved - but not supported by this os versions
    // As result - emoji - corrupted
    if (browserInfo.name === BrowserNames.Safari && Number(browserInfo.version) <= 14) {
      if (EmojiBlackList.includes(emoji.name)) {
        return false
      }
    }

    // handle problem with some emoji - display wrong image for some custom emoji
    if (emoji.name.toLowerCase().includes('white')) {
      return false;
    }

    return true;
  }

  const setPreloadedEmojiSheets = () => {
    let domain = getCloudFrontDomain(productName);

    return `${domain}/images/emoji-sheet-apple.png`
  }

  return (
    <div className='zchat__emoji' ref={picker} style={(!StorageState.roomId && !value) ? {pointerEvents: 'none', opacity: '.3'} : {pointerEvents: 'auto', opacity: 'unset'}}>
      <div className='zchat__emoji-dialog'>
        {showPicker && (
          <Picker
            emoji=''
            title=''
            set='google'
            onSelect={addEmoji}
            perLine={8}
            sheetSize={64}
            backgroundImageFn={setPreloadedEmojiSheets}
            emojisToShowFilter={filterEmoji}
          />
        )}
      </div>
      <div className='zchat__emoji-button' onClick={togglePicker}>
        <div className='zchat__emoji-icon'/>
      </div>
    </div>
  );
};

export default Emoji;
