const Events = {
  onBeforeGetHistory: 'onBeforeGetHistory',
  onBeforeClose: 'onBeforeClose',

  onUIInitialized: 'onUIInitialized',
  onConnecting: 'onConnecting',
  onGetHistory: 'onGetHistory',
  onGetAgentProfileInfo: 'onGetAgentProfileInfo',
  onAuthorized: 'onAuthorized',
  onConnected: 'onConnected',
  onReady: 'onReady',
  onRoomCreate: 'onRoomCreate',
  onDisconnected: 'onDisconnected',
  onMessageSend: 'onMessageSend',
  onClose: 'onClose',
  onHide: 'onHide',
  onShowAutomessagesOptions: 'onShowAutomessagesOptions',
  onShowAutomessagesExtended: 'onShowAutomessagesExtended',
  onSessionClose: 'onSessionClose',
  onCriticalError: 'onCriticalError',
  onAgentConnected: 'onAgentConnected',
  onUserToggleChat: 'onUserToggleChat',
  onUserClickLink: 'onUserClickLink',
  onDownloadCommand: 'onDownloadCommand',
  onCheckoutOpen: 'onCheckoutOpen',
  onCheckoutOpenByUser: 'onCheckoutOpenByUser',
  onHideAndScroll: 'onHideAndScroll',
  onOpenWithButton: 'onOpenWithButton',

  onIncomingMessage: 'onIncomingMessage',
  onOutgoingMessage: 'onOutgoingMessage',
  onCommentMessage: 'onCommentMessage',
  onSystemMessage: 'onSystemMessage',
  onAgentTypingMessage: 'onAgentTypingMessage',
  onAgentUnTypingMessage: 'onAgentUnTypingMessage',
}

export default Events;
