import {ChatConfig} from '../../interfaces/zchat';

export interface ChainerInterface {
  setFinal(callback: FinalHandler): void;

  addHandler(f: Handler): void;

  processMessage(context: string, handlers: Array<Handler>): void;

  sendMessage(context: string): void;
}

export type Handler = (next: (context: string) => void, context: string) => void;

type FinalHandler = (context: string) => void;

// Chain of Responsibility Pattern
export class Chainer implements ChainerInterface {
  handlers: Array<Handler>;
  finalHandler: FinalHandler;
  chatConfig: ChatConfig;

  constructor(chatConfig: ChatConfig) {
    this.handlers = [];
    this.chatConfig = chatConfig;
    this.finalHandler = () => {
      // will be overridden after initialization
    };
  }

  public setFinal(callback: FinalHandler): void {
    this.finalHandler = callback;
  }

  public addHandler(f: Handler): void {
    this.handlers.push((f).bind(this));
  }

  processMessage(context: string, handlers: Array<Handler>): void {
    if (!handlers.length) {
      this.finalHandler(context);
      return;
    }

    let newHandlers = [...handlers];
    let firstHandler = newHandlers.shift();

    if (!firstHandler) {
      console.error('Empty handlers');
      return;
    }

    let next = (context: string) => this.processMessage(context, newHandlers);
    firstHandler(next, context);
  }

  public sendMessage(context: string): void {
    this.processMessage(context, this.handlers);
  }
}
