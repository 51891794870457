export const defaultConfig = {
  chatConfig: {
    autoFocus: false,
    sendOnEnter: true,
    autoConnect: false,
    autoOpen: false,
    dnd: true,
    showBubble: true,
    reInitializeOnCloseSession: true,
    defaultAgentName: 'Agent',
    agentNameFromHistory: false,
    connectRetryLimit: 9,
    messageMinLength: 1,
    showAgentPhoto: false,
    startChattingTooltip: {
      show: false,
      timeoutToShow: 30,
      showNextTimeAfterClose: false,
    },
    chatExternalEvents: {
      chat_started: 'zchat_chat_started',
      chat_connected: 'zchat_user_connected',
      chat_timeout: 'zchat_polling_broken',
      chat_shown: 'zchat_user_showed_chat',
      chat_hidden: 'zchat_user_hidden_chat',
      click_link: 'zchat_link_clicked',
    }
  },
  roomConfig: {
    lang: 'en',
  }
};
