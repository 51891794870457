import {clarioConfig, defaultConfig, mackeeperConfig, web2appConfig, zoomsupportConfig, wtaConfig, goinvisibleConfig} from '../../../configs';
import {Config} from '../../../interfaces/zchat';
import {InitConfigStorageKey, ProductNames} from '../../../constants';
import merge from 'deepmerge';
import Schema, {ValidationError} from 'validate'
import {ValidationConfigSchema} from './configSchema';

// Create initial config - given the product name
// override config possibility for tests
// Firstly process config passed by initialization
// If init config passed in local storage - override by config from storage
export default class СonfigManager {
  private static instance: СonfigManager;

  _config: Config;
  _initConfig: Config | null;
  _validationSchema: Schema;
  _errors: ValidationError[] | null;

  constructor(config?: Config) {
    this._config = defaultConfig;
    this._initConfig = config || null;
    this._validationSchema = new Schema(ValidationConfigSchema);
    this._errors = null;
    this.buildConfig();
  }

  private buildConfig() {
    this.mergeConfig();
    this.validateConfig();

    if (this._errors && this._errors.length) {
      throw new Error(`Initialize config doesnt valid: ${this._errors}`);
    }
    return this._config;
  }

  public get config() {
    return this._config;
  }

  private mergeConfig() {
    if (!this._initConfig) {
      throw new Error('Init config - not passed. Product name is required param for Zchat initialization.');
    }

    switch (this._initConfig.chatConfig.productName) {
      case ProductNames.mackeeper:
        this._config = (merge.all([defaultConfig, mackeeperConfig, this._initConfig]) as Config);
        break;
      case ProductNames.mkSoft:
        this._config = (merge.all([defaultConfig, mackeeperConfig, this._initConfig, {chatConfig: {showBubble: false, dnd: false}}]) as Config);
        break;
      case ProductNames.clario:
        this._config = (merge.all([defaultConfig, clarioConfig, this._initConfig]) as Config);
        break;
      case ProductNames.web2app:
        this._config = (merge.all([defaultConfig, web2appConfig, wtaConfig, this._initConfig]) as Config);
        break;
      case ProductNames.zoomsupport:
        this._config = (merge.all([defaultConfig, zoomsupportConfig, this._initConfig]) as Config);
        break;
      case ProductNames.goinvisible:
        this._config = (merge.all([defaultConfig, goinvisibleConfig, this._initConfig]) as Config);
        break;
      default:
        throw new Error(`Unknown product - '${this._initConfig.chatConfig.productName}' is passed during initialization`);
    }

    let configFromStorage = JSON.parse(localStorage.getItem(InitConfigStorageKey) || '{}');

    if (configFromStorage) {
      this._config = (merge.all([this._config, configFromStorage]) as Config);
    }
  }

  private validateMessageConfigValue() {
    const {messageMaxLength, messageMinLength} = this._config.chatConfig;
    if (messageMaxLength && messageMaxLength && messageMaxLength < messageMinLength) {
      let error = {
        expose: true,
        message: 'chatConfig messageMaxLength <  messageMinLength',
        path: 'chatConfig.messageMaxLength',
        stack: 'Error: chatConfig.messageMaxLength must be >  messageMinLength',
        status: 400
      }
      if (this._errors) {
        this._errors.push(error)
      }
      this._errors = [error];
    }
  }

  private validateConfig(): void {
    this._errors = this._validationSchema.validate(this._config);
    this.validateMessageConfigValue();
    return;
  }

  public static getInstance(config?: Config): СonfigManager {
    if (!СonfigManager.instance) {
      СonfigManager.instance = new СonfigManager(config);
    }

    return СonfigManager.instance;
  }
}
