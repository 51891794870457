import {ChatConfig, Config, RoomConfig} from '../interfaces/zchat';
import {ProductNames, MessageTypes} from '../constants';
import MKClient from './mkClient';
import ClarioClient from './clarioClient';
import GOIClient from './goiClient';
import {ClientInterface} from './commonClient';
import {EventEmitter} from 'events';
import Events from '../constants/events';
import {MessageType} from '../interfaces/api';
import {MessageInterface} from '../store/messages/types';
import {parseXaction, XactionsNames} from './xactionParser';
import * as Sentry from '@sentry/browser';
import {StorageState} from '../foundations/services/storageStateManager';
import getClientInfo from '../foundations/utils/getClientInfo';
import capitalize from '../foundations/utils/capitalize';

export interface ChatClientInterface {

  sendMessage(message: string, messageType?: MessageType): Promise<void>;

  askHistory(): Promise<void>;

  isConnected(): boolean;
}

// ChatClient - the layer between clients and UI.
// Made in order to receive commands from the UI.
// It stores the states of clients internally, knows which client is responsible for executing commands.
// In fact, this is a client api manager.

class ChatClient implements ChatClientInterface {
  private static instance: ChatClient;
  chatConfig: ChatConfig;
  roomConfig: RoomConfig;
  client: ClientInterface | null;
  eventEmitter: EventEmitter;

  constructor(config: Config, eventEmitter: EventEmitter) {
    this.chatConfig = config.chatConfig;
    this.roomConfig = config.roomConfig;
    this.client = null;
    this.eventEmitter = eventEmitter;
  }

  public async sendMessage(message: string, messageType?: MessageType): Promise<void> {
    await this.authorize();
    await this.client?.sendMessage(message, messageType);
  }

  public subscribeToMessages(message: MessageInterface): void {
    switch (message.type) {
      case MessageTypes.incoming:
        this.eventEmitter.emit(Events.onIncomingMessage, message);
        break;
      case MessageTypes.comment:
        this.eventEmitter.emit(Events.onCommentMessage, message);
        break;
      case MessageTypes.outgoing:
        this.eventEmitter.emit(Events.onOutgoingMessage, message);
        break;
      case MessageTypes.xaction:
        this.processXaction(message);
        break;
      case MessageTypes.system:
        this.eventEmitter.emit(Events.onSystemMessage, message);
        break;
      case MessageTypes.agent_typing:
        this.eventEmitter.emit(Events.onAgentTypingMessage);
        break;
      case MessageTypes.agent_untyping:
        this.eventEmitter.emit(Events.onAgentUnTypingMessage);
        break;
      default:
    }
  }

  public async authorize(): Promise<boolean> {
    if (this.client && this.client.isAuthorized()) {
      return Promise.resolve(true);
    }

    if (!this.client) {
      this.setUpClient();
    }

    this.eventEmitter.emit(Events.onConnecting);
    if (!this.client?.isAuthorized()) {
      await (this.client as ClientInterface).authorize();
      this.eventEmitter.emit(Events.onAuthorized);
    }

    this.eventEmitter.emit(Events.onRoomCreate);
    await (this.client as ClientInterface).createRoom();
    await this.askHistory();
    await (this.client as ClientInterface).receiveMessage();
    await this.getAgentProfile();
    this.eventEmitter.emit(Events.onReady);
    return Promise.resolve(true);
  }

  public async createRoom(): Promise<void> {
    if (this.client && this.client.roomCreated) {
      return Promise.resolve();
    }

    await this.authorize();
    await (this.client as ClientInterface).createRoom();
  }

  public async askHistory(): Promise<void> {
    await this.authorize();
    await (this.client as ClientInterface).receiveMessage();

    // Need to add this condition after authorize method call
    // because by default if client was not setup - during authorization it should setup
    if (!this.client) {
      return;
    }

    this.eventEmitter.emit(Events.onBeforeGetHistory);
    let roomHistory = await this.client.getRoomHistory();
    this.eventEmitter.emit(Events.onGetHistory, roomHistory);
  }

  public async getAgentProfile(): Promise<void> {
    // Need to add this condition after authorize method call
    // because by default if client was not setup - during authorization it should setup
    if (!this.client) {
      return;
    }

    let agentProfile = await this.client?.getAgentProfileInfo();
    this.eventEmitter.emit(Events.onGetAgentProfileInfo, agentProfile);
  }

  public isConnected(): boolean {
    return this.client?.isConnected || false;
  }

  public isAuthorized(): boolean {
    return this.client?.isAuthorized() || false;
  }

  private setUpClient() {
    switch (this.chatConfig.productName) {
      case ProductNames.clario:
        this.client = new ClarioClient({roomConfig: this.roomConfig, chatConfig: this.chatConfig}, this.eventEmitter);
        break;
      case ProductNames.web2app:
        this.client = new ClarioClient({roomConfig: this.roomConfig, chatConfig: this.chatConfig}, this.eventEmitter);
        break;
      case ProductNames.mackeeper:
        this.client = new MKClient({roomConfig: this.roomConfig, chatConfig: this.chatConfig}, this.eventEmitter);
        break;
      case ProductNames.zoomsupport:
        this.client = new MKClient({roomConfig: this.roomConfig, chatConfig: this.chatConfig}, this.eventEmitter);
        break;
      case ProductNames.mkSoft:
        this.client = new MKClient({roomConfig: this.roomConfig, chatConfig: this.chatConfig}, this.eventEmitter);
        break;
      case ProductNames.goinvisible:
        this.client = new GOIClient({roomConfig: this.roomConfig, chatConfig: this.chatConfig}, this.eventEmitter);
        break;
      default:
        throw new Error(`Unknown product ${this.chatConfig.productName}`);
    }

    this.client.setMessageCallback((this.subscribeToMessages).bind(this));
  }

  private processInfoXaction() {
    let clientInfo = getClientInfo();
    let preparedClientInfo = '';

    Object.entries(clientInfo).forEach(([key, value]) => {
      preparedClientInfo += `<b>${capitalize(key)}:</b> ${value}<br/>`
    })

    this.sendMessage(preparedClientInfo, MessageTypes.comment);
  }

  private processXaction(message: MessageInterface) {
    let parseXactionInfo = parseXaction(message);
    switch (parseXactionInfo.actionName) {
      case XactionsNames.info:
        this.processInfoXaction();
        break;
      case XactionsNames.ping:
        this.sendMessage(`ping_ok (${StorageState.clientId})`, MessageTypes.comment);
        break;
      case XactionsNames.close:
        if (!this.chatConfig.reInitializeOnCloseSession) {
          this.client?.closeSession();
          return;
        }
        this.client?.eraseAuthorization();
        this.shutDown();
        this.eventEmitter.emit(Events.onBeforeClose);
        break;
      case XactionsNames.show_auto_messages_options:
        this.eventEmitter.emit(Events.onShowAutomessagesOptions);
        break;
      case XactionsNames.show_auto_messages_extended:
        this.eventEmitter.emit(Events.onShowAutomessagesExtended);
        break;
      case XactionsNames.hide:
        this.eventEmitter.emit(Events.onHide);
        break;
      case XactionsNames.scroll:
        this.eventEmitter.emit(Events.onHideAndScroll);
        break;
      case XactionsNames.show_checkout:
        this.eventEmitter.emit(Events.onCheckoutOpen);
        break;
      case XactionsNames.agent_profile:
        if (this.chatConfig.agentNameFromHistory && StorageState.agentName && StorageState.agentName.trim().length) {
          this.eventEmitter.emit(Events.onAgentConnected, parseXactionInfo.actionArguments)
          break;
        }
        if (parseXactionInfo.actionArguments?.public_name && parseXactionInfo.actionArguments?.public_name.trim().length) {
          StorageState.setAgentName(parseXactionInfo.actionArguments?.public_name);
        }
        this.eventEmitter.emit(Events.onAgentConnected, parseXactionInfo.actionArguments);
        break;
      case XactionsNames.download:
        this.eventEmitter.emit(Events.onDownloadCommand);
        break;
      default:
        Sentry.addBreadcrumb({
          category: MessageTypes.xaction,
          level: Sentry.Severity.Error,
        });
        Sentry.captureMessage(`${MessageTypes.xaction} not defined ${message}`, Sentry.Severity.Error);
    }
  }

  public shutDown() {
    this.client?.closeChat();
  }

  public static getInstance(config: Config, eventEmitter: EventEmitter): ChatClient {
    if (!ChatClient.instance) {
      ChatClient.instance = new ChatClient(config, eventEmitter);
    }

    return ChatClient.instance;
  }
}

export default ChatClient;
