import React, {FunctionComponent} from 'react';
import {RootState} from "../../../store";
import {useSelector} from "react-redux";

const Disconnected: FunctionComponent = () => {
    const chatConfig = useSelector((state: RootState) => state.chat.chatConfig);

    return (
        <div className='zchat__status'>
          <span className='zchat__status-icon zchat__status-icon--disconnected'/>
          <p className='zchat__status-text'>{chatConfig?.disconnectedStatusText ? chatConfig?.disconnectedStatusText : 'Write here to start your chat'}</p>
        </div>
    );
}

export default Disconnected;
