import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {EventEmitter} from 'events';
import {defaultConfig} from './configs';
import store from './store';
import {ChatConfig, ChatExternalEventsType, Config, RoomConfig} from './interfaces/zchat';
import {getCloudFrontDomain, NodeEnv} from './constants';
import ChatClient from './chatClient';
import ZchatUI from './components/zchatUI';
import {Chainer, ChainerInterface, Handler} from './foundations/utils/chainer';
import {SentryBrowser} from './foundations/services/sentry';
import ChatExternalEventsService from './foundations/services/chatExternalEvents/chatExternalEvents';
import Events from './constants/events';
import СonfigManager from './foundations/services/configManager';
import {StorageState} from './foundations/services/storageStateManager';
import {closeChat, openChat} from './store/chat/actions';
import {MessageType} from './store/messages/types';
import {MessageQueueService, MessageQueueInterface} from './foundations/services/messageQueue';

declare var NODE_ENV: string;
declare var RELEASE_ID: string;

export interface ZchatInterface {
  init(config?: Config): Zchat;

  onEvent(event: string, callback: (data: any) => void, once: false): void;

  addMessageHandler(handler: Handler): void;

  sendMessage(message: string, messageType?: MessageType): void;

  showChat(): Promise<void>;

  isConnected(): boolean;

  gracefulShutDown(): Promise<void>;

  reInitialize(config: Config): Promise<void>;
}

// Root component, project entry point
// Initialize all other components -  chat client, events emitter, sentry, UI, external events, chainer
// Responsible for public Zchat methods
export class Zchat implements ZchatInterface {
  private static instance: Zchat;
  roomConfig: RoomConfig;
  chatConfig: ChatConfig;
  config: Config;
  chatClient: ChatClient | null;
  eventEmitter: EventEmitter;
  messageMiddleware: ChainerInterface | null;
  chatExternalEventsService: ChatExternalEventsService | null;
  messageQueueService: MessageQueueInterface | null;
  confgManager: СonfigManager | null;

  private constructor() {
    this.roomConfig = defaultConfig.roomConfig;
    this.chatConfig = defaultConfig.chatConfig;
    this.config = {
      roomConfig: this.roomConfig,
      chatConfig: this.chatConfig
    };
    this.chatClient = null;
    // Pub-Sub Pattern
    this.eventEmitter = new EventEmitter();
    // Chainer for sending messages
    this.messageMiddleware = null;
    // Service for handling system events sent to CRM upon user actions.
    this.chatExternalEventsService = null;
    // Service for handling messages.
    this.messageQueueService = null;
    // Service for building and validate initialize config
    this.confgManager = null;
    this.subscribeToInternalEvents();
  }

  // Subscribe to listen internal events
  private subscribeToInternalEvents() {
    this.eventEmitter.on(Events.onBeforeClose, this.restartAfterClose.bind(this));
    this.eventEmitter.on(Events.onUIInitialized, this.initialPrepareChat.bind(this));
    this.eventEmitter.on(Events.onCriticalError, this.handleCriticalChatErrors.bind(this));
  }

  // Since Zcaht is multi-product - mk, clario
  // The necessary theme is connected depending on the config
  // If the theme is not specified on the config - will be applied the default them - now is for clario
  private initializeTheme(): void {
    if (NODE_ENV !== NodeEnv.production) {
      return;
    }
    let head = document.head;
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = this.getThemeLink();

    head.appendChild(link);
  }

  // All styles are deployed on S3 bucket
  // Divided by product
  private getThemeLink(): string {
    let {productName} = this.chatConfig;
    let domain = getCloudFrontDomain(productName);

    return `${domain}/theme/zchat.css?ver=${RELEASE_ID}`
  }

  // Creating Zchat root dom element
  // Append after body - to prevent even the smallest chance of breaking the UI on the sites with which it integrates
  private createWrapper(): void {
    let appendChatRootElement = this.chatConfig.rootElement ? document.querySelector(this.chatConfig.rootElement) : document.documentElement;

    if (!appendChatRootElement) return;

    const appContainer = document.createElement('div');
    appContainer.id = 'zchat-widget';
    appendChatRootElement.appendChild(appContainer);
  }

  private deleteZChatWrapper(): void {
    const zChatWrapper = document.getElementById('zchat-widget');
    zChatWrapper?.remove()
  }

  private initializeChatClient(config: Config, eventEmitter: EventEmitter): void {
    this.chatClient = new ChatClient(config, eventEmitter);
  }

  private initializeChatExternalEvents(): void {
    this.chatExternalEventsService = new ChatExternalEventsService(this.eventEmitter, this.chatClient, this.messageQueueService, (this.chatConfig.chatExternalEvents as ChatExternalEventsType));
  }

  private initializeMessageQueue(): void {
    this.messageQueueService = new MessageQueueService(this.eventEmitter, this.chatClient);
  }

  private initializeMessageMiddleware(): void {
    this.messageMiddleware = new Chainer(this.chatConfig);
  }

  private initializeReact(): void {
    this.createWrapper();

    ReactDOM.render(
        <Provider store={store}>
          <ZchatUI
            chatConfig={this.chatConfig}
            chatClient={this.chatClient}
            messageQueueService={this.messageQueueService}
            eventEmitter={this.eventEmitter}
            messageMiddleware={this.messageMiddleware}
          />
        </Provider>,

      document.getElementById('zchat-widget'));
  }

  // Make a decision how exactly chat should be shown.
  // There can be one of four options
  // - show only chat icon
  // - show empty chat without history
  // - show chat with history, open crm connection
  // - show chat by autoOpen option - connect or disconnect - depends on roomID or autoConnect option
  private initialPrepareChat() {
    // disabling of empty chat opening
    StorageState.roomId && StorageState.isOpenState ? store.dispatch(openChat()) : store.dispatch(closeChat());

    // handle autoOpen option
    if (this.chatConfig.autoOpen) {
      store.dispatch(openChat());
    }

    // Handle case when chat is initialized with autoConnect = true
    if (this.chatConfig.autoConnect) {
      this.chatClient?.askHistory();
      return;
    }

    if (!StorageState.isOpenState) {
      return;
    }

    // Customer with history
    // Trigger chatClient command for getting history.
    // Chat history will be emitted as event 'onGetHistory'.
    if (StorageState.roomId) {
      this.chatClient?.askHistory();
    }
  }

  // Handle corner cases when zchat could not continue work
  // Some cases:
  // - deviceId for authorised chat - does not match
  private async handleCriticalChatErrors() {
    const zchatWidget = document.getElementById('zchat-widget');

    if (!zchatWidget) {
      return;
    }

    zchatWidget.remove();
  }

  private async restartAfterClose() {
    this.reInitialize(this.config);
  }

  // PUBLIC METHODS
  // Main init chat method
  public init(config?: Config): Zchat {
    this.confgManager = new СonfigManager(config);
    config = this.confgManager.config;

    if (config) {
      let {roomConfig, chatConfig} = config;
      this.config = config;
      this.roomConfig = roomConfig;
      this.chatConfig = chatConfig;
    }

    if (StorageState.roomType && StorageState.roomType.trim().length && StorageState.roomType !== this.roomConfig.type) {
      StorageState.eraseAll();
    }

    StorageState.setRoomType(this.roomConfig.type);
    SentryBrowser.init();
    this.initializeTheme();
    this.initializeChatClient(this.config, this.eventEmitter);
    this.initializeMessageMiddleware();
    this.initializeMessageQueue();
    this.initializeChatExternalEvents();
    this.initializeReact();
    return Zchat.getInstance();
  }

  // Bind events on ZChat
  public onEvent(event: string, callback: (data: any) => void, once: false): void {
    if (event && callback && typeof (callback) === 'function') {
      if (once) {
        this.eventEmitter.once(event, (data) => callback(data));
        return
      }
      this.eventEmitter.on(event, (data) => callback(data));
    }
  }

  // Add ability to add handlers for message processing, before they will be delivered to chat client
  public addMessageHandler(handler: Handler): void {
    this.messageMiddleware?.addHandler(handler);
  }

  // Add ability to send message to crm
  public sendMessage(message: string, messageType?: MessageType): void {
    this.messageQueueService?.sendMessage({message, messageType});
  }

  // Show Chat
  public async showChat(): Promise<void> {
    store.dispatch(openChat());
  }

  // Check ZChat  status -  is connected or disconnected to CRM at a specific time.
  public isConnected(): boolean {
    return this.chatClient?.isConnected() || false;
  }

  // Add ability to gracefully close chat
  public async gracefulShutDown(): Promise<void> {
    this.chatClient?.shutDown();
    this.chatExternalEventsService?.shutDown();
    this.messageQueueService?.shutDown();
    store.dispatch(closeChat());
    this.deleteZChatWrapper();
    this.eventEmitter.emit(Events.onClose);
    this.eventEmitter.removeAllListeners();
  }

  // Re-initialize chat with different room type or chat config
  public async reInitialize(config: Config): Promise<void> {
    await this.gracefulShutDown();
    this.init(config);
  }

  public static getInstance(): Zchat {
    if (!Zchat.instance) {
      Zchat.instance = new Zchat();
    }

    return Zchat.instance;
  }
}
