export default function getLandName(product: 'clario' | 'mackeeper' | undefined = undefined): string | undefined {
    let currentProduct = window.location.href.indexOf('clario') !== -1 ? 'clario' : 'mackeeper';

    if (product && currentProduct === product) {
        let landName = window.location.href.split('/land/').length > 1 ? window.location.href.split('/land/')[1] : '';

        if (landName.indexOf('/') !== -1 || landName.indexOf('?') !== -1) {
            landName = landName.split('?')[0];
            landName = landName.split('/')[0];
        }

        return landName;
    }
}
