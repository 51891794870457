import React, {FunctionComponent, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {ConnectionStatus} from '../../../constants';
import {RootState} from '../../../store';
import DisconnectedWeb2app from './disconnectedWeb2app';
import ConnectedWeb2app from './connectedWeb2app';
import Connected from "./connected";

const StatusesWeb2app: FunctionComponent = () => {
  const connectionStatus = useSelector((state: RootState) => state.chat.connectionStatus);
  const agentProfile = useSelector((state: RootState) => state.agent.agentProfile);

  const determineConnectionStatus = () => {
    switch (connectionStatus) {
      case ConnectionStatus.Connected:
        return <ConnectedWeb2app/>;
      case ConnectionStatus.AgentConnected:
        return <ConnectedWeb2app/>;
      case ConnectionStatus.Connecting:
        return <DisconnectedWeb2app/>;
      default:
        return <DisconnectedWeb2app/>;
    }
  }

  return (
    <Suspense fallback={null}>
      <>
        {determineConnectionStatus()}
      </>
    </Suspense>
  );
}

export default StatusesWeb2app;

