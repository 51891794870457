import {ChatConfig} from '../../interfaces/zchat';

export default function delay(retryCount: number, chatConfig: ChatConfig): Promise<void> {
  // Override the exponential backoff strategy to a regular interval - defined in chat config during chat initialization
  if (chatConfig.connectRetryInterval) {
    return new Promise(resolve => setTimeout(resolve, chatConfig.connectRetryInterval));
  }

  // Do not increase delay timout after 6 requests
  if (retryCount > 6) {
    return new Promise(resolve => setTimeout(resolve, 60000));
  }

  return new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
}
