import {CloseChatAction} from '../chat/types';

export const SET_AGENT_TYPING_STATUS = 'SET_AGENT_TYPING_STATUS';
export const SET_AGENT_PROFILE_INFO = 'SET_AGENT_PROFILE_INFO';

export interface AgentState {
  agentTyping: boolean,
  agentProfile: AgentProfile | null,
}

export type AgentProfile = {
  photo_url: string,
  public_name: string,
  rating_score: 0,
  superpowers: string,
  customers_helped: 0,
  hobby: string,
  tip_for_security: string
}

export interface SetAgentTypingStatus {
  type: typeof SET_AGENT_TYPING_STATUS
  payload: {status: boolean},
}

export interface SetAgentProfileInfo {
  type: typeof SET_AGENT_PROFILE_INFO
  payload: AgentProfile | null,
}

export type AgentActionTypes = SetAgentTypingStatus | SetAgentProfileInfo | CloseChatAction;
