import React, {FunctionComponent} from 'react';
import {getCloudFrontDomain} from "../../../constants";

const ConnectedWeb2app: FunctionComponent = () => {
  let domain = getCloudFrontDomain('web2app');

  return (
    <div className='zchat__status'>
        <img src={`https://files.clario.co/images/ChatAvatars/MKCR-5413_144_144_m_8.png`} className={'zchat__status-icon'} alt="Operator"/>
      <p className='zchat__status-text zchat__status-text--dark'>Connected to <b>Andrew</b></p>
    </div>
  );
}

export default React.memo(ConnectedWeb2app);
