import {AgentProfile, SET_AGENT_PROFILE_INFO, SetAgentTypingStatus, SET_AGENT_TYPING_STATUS, SetAgentProfileInfo} from './types';

export function setAgentTypingStatus(status: boolean): SetAgentTypingStatus {
  return {
    type: SET_AGENT_TYPING_STATUS,
    payload: {status}
  }
}

export function setAgentProfileInfo(info: AgentProfile | null): SetAgentProfileInfo {
  return {
    type: SET_AGENT_PROFILE_INFO,
    payload: info
  }
}
