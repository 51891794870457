import React, {FunctionComponent, useRef} from 'react';
import AgentAvatar from '../agentAvatar';
import {TooltipState} from '../../store/chat/types';
import {useZchatContext} from '../zchatUI';

type Props = {
  text: string;
  setTooltipState(state: TooltipState): void;
}

const Tooltip: FunctionComponent<Props> = ({text = '', setTooltipState}) => {
  const closeButtonRef = useRef<any>(null);
  const {toggleChat} = useZchatContext();

  if (!toggleChat) {
    return null;
  }

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (closeButtonRef.current && closeButtonRef.current.contains(event.target as Node)) {
      setTooltipState({isOpen: false});
      return;
    }
    toggleChat();
  }

  return (
    <div className='zchat__tooltip' onClick={handleClick}>
      <div className='zchat__tooltip-content'>
        <AgentAvatar className='zchat__tooltip-avatar'/>
        <div className='zchat__tooltip-text'>{text}</div>
        <div className={`zchat__button--close zchat__tooltip-close`}
             ref={closeButtonRef}
             onClick={handleClick}/>
        <div className='zchat__tooltip-corner'/>
      </div>
    </div>
  )
}

export default Tooltip;
