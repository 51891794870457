import React, {FunctionComponent} from 'react';
import Loader from '../loader';
import useDetermineAgentName from '../../foundations/hooks/useDetermineAgentName';
import {ConnectionStatus} from '../../constants';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';

type Props = {
  customName?: string
}

const AgentTyping: FunctionComponent<Props> = ({customName}) => {
  const connectionStatus: ConnectionStatus = useSelector((state: RootState) => state.chat.connectionStatus);

  const agentName = useDetermineAgentName();

  // Handle reInitializeOnCloseSession option from config
  // After session close - do not show agent typing component - event send by crm
  if (connectionStatus === ConnectionStatus.SessionClose) {
    return null;
  }

  return (
    <Loader className='zchat__loader--typing'>
      <span className='zchat__typing'>{`${customName || agentName} is typing`}</span>
    </Loader>
  );
}

export default AgentTyping;
