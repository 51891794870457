import React, {FunctionComponent} from 'react';

const Connecting: FunctionComponent = () => {
  return (
    <div className='zchat__status'>
      <span className='zchat__status-icon zchat__status-icon--disconnected'/>
      <p className='zchat__status-text'>Connected to Clario
      </p>
    </div>
  );
}

export default React.memo(Connecting);
