import {connect} from 'react-redux';
import {RootState} from '../../store';
import {ChatConfig} from '../../interfaces/zchat';
import Header from './header';

type StateType = {
  chatConfig: ChatConfig | null,
}

const mapStateToProps = (state: RootState): StateType => ({
  chatConfig: state.chat.chatConfig,
});


export default connect(
  mapStateToProps,
  null,
)(Header);
