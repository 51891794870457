import getLandName from "../../foundations/utils/getLandName";

export type SuggestionsBlogProductConfigType = {
  id: string,
  text: string,
  event?: string,
};



export type SuggestionsConfigType = Array<{
  [key: string]: Array<SuggestionsBlogProductConfigType>
}>

export let SuggestionsSiteConfig: Array<SuggestionsBlogProductConfigType>;

SuggestionsSiteConfig = [
    {
        id: 'question_about_mk',
        text: 'Question about MacKeeper',
        event: '//questions_about_mk'
    },
    {
        id: 'subscription_cancellation_or_refund',
        text: 'Subscription Cancellation/Refund',
        event: '//subscription_cancellation_or_refund'
    },
    {
        id: 'issues_with_mk',
        text: 'Issues with my Mac',
        event: '//issues_with_mk'
    },
    {
        id: 'other',
        text: 'Other',
        event: '//other'
    }
]

