import React, {FunctionComponent} from 'react';
import {SuggestionsNewConfig, SuggestionsNewConfigWeb2app, SuggestionsNewConfigWta} from './suggestionsNewConfig';
import SuggestionsNewList from './suggestionsNewList';
import {ChatConfig} from "../../interfaces/zchat";

type props = {
    chatConfig: ChatConfig | null,
}

const SuggestionsNew: FunctionComponent<props> = ({chatConfig}) => {
  return (
    <div className={`zchat__suggestions ${chatConfig?.productName === 'web2app' ? '--web2app' : ''}`}>
      <SuggestionsNewList chatConfig={chatConfig} suggestions={chatConfig?.productName === 'web2app' ? chatConfig?.theme === 'wta' ? SuggestionsNewConfigWta : SuggestionsNewConfigWeb2app : SuggestionsNewConfig}/>
    </div>
  )
}

export default SuggestionsNew;
